import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>GENX PEARL CITY</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Kothwan, Near Rupaspur Nahar, Bialey Road, Patna,
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  <strong>GENX PEARL CITY</strong> is an upcoming premium
                  residential development that sets a new standard for luxury
                  living in Patna. Featuring six towering, state-of-the-art
                  buildings, this project promises to deliver a sophisticated
                  and elevated lifestyle, blending world-class amenities with
                  cutting-edge architectural design. Located in the serene and
                  growing neighborhood of Kothwan, near Rupaspur Nahar, Bialey
                  Road, Patna, GENX PEARL CITY offers the perfect balance of
                  accessibility, tranquility, and modernity. The design and
                  planning of this exquisite project are executed by the
                  renowned architectural firm Hafeez Contractor, whose vast
                  experience and reputation for excellence guarantee a truly
                  iconic development.
                </p>
                <p>
                  GENX PEARL CITY is designed not only as a living space but as
                  a destination that offers a luxurious, comfortable, and
                  convenient life to its residents. With 3BHK, 4BHK, and
                  Penthouse options available, this development caters to a wide
                  range of discerning buyers who seek the best in terms of
                  space, comfort, and luxury.
                </p>
                

                <h4 className="title-2">Location:</h4>
                <div>
                  <p>
                    Located in the peaceful locality of Kothwan, near Rupaspur
                    Nahar, Bialey Road, Patna, GENX PEARL CITY is strategically
                    positioned to offer easy access to major city hubs while
                    being surrounded by natural beauty. The neighborhood is
                    rapidly developing, with excellent connectivity to key areas
                    such as the city center, educational institutions,
                    hospitals, and commercial establishments. The proximity to
                    major roads and highways ensures that residents are
                    well-connected to the rest of Patna, making it an ideal
                    location for both families and professionals.
                  </p>
              
                </div>

                <h4 className="title-2">Design & Architecture:</h4>
                <div>
                  <p>
                    GENX PEARL CITY is a masterpiece of contemporary design,
                    with Hafeez Contractor’s expert touch ensuring that every
                    aspect of the project reflects luxury, functionality, and
                    aesthetic appeal. The six towers of GENX PEARL CITY are
                    designed to provide panoramic views of the surrounding area
                    while maximizing natural light and ventilation for a
                    healthier, more sustainable living experience.
                  </p>
                  
                  <p>
                    The buildings’ facades incorporate sleek, modern lines and
                    are crafted with high-quality materials that not only
                    enhance the visual appeal of the complex but also ensure
                    durability and longevity. The innovative design emphasizes
                    spaciousness, making the most of available land while
                    creating a harmonious balance between urban living and
                    nature.
                  </p>
                </div>

                <h4 className="title-2">Residential Units:</h4>
                <div>
                  <p>
                    GENX PEARL CITY offers three distinct types of residential
                    units to cater to the diverse needs of its residents:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>3BHK Apartments: </strong>Perfectly designed for
                        medium-sized families, the 3BHK apartments feature open
                        floor plans, expansive living spaces, and large windows
                        for maximum natural light. The thoughtfully designed
                        interiors include spacious bedrooms, modern kitchens,
                        and elegant bathrooms, ensuring comfort and style.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>4BHK Apartments: </strong>
                        Ideal for larger families or those seeking more space,
                        the 4BHK apartments at GENX PEARL CITY offer even more
                        room for living, working, and entertaining. These units
                        come with additional features such as larger balconies,
                        walk-in closets, and a luxurious master suite to enhance
                        the living experience.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Penthouses: </strong>For those who demand the
                        very best in luxury living, the penthouses at GENX PEARL
                        CITY offer opulence and exclusivity. These high-end
                        units are the epitome of refined living, with expansive
                        layouts, private terraces, and high-end finishes. The
                        penthouses are designed to provide an unmatched level of
                        comfort, with large living and dining areas, multiple
                        bedrooms, and panoramic views of the surrounding
                        landscape.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">World-Class Amenities:</h4>
                <div>
                  <p>
                    GENX PEARL CITY is designed to offer its residents not just
                    a home, but a complete lifestyle. The project features a
                    host of world-class amenities aimed at providing
                    convenience, relaxation, and entertainment for every member
                    of the family. The key amenities include:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Clubhouse & Social Spaces:</strong>A luxurious
                        clubhouse equipped with a variety of social spaces,
                        including a lounge, multipurpose hall, and event venues
                        for hosting parties, meetings, and other functions.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Swimming Pool & Spa:</strong>The development
                        features an exclusive swimming pool and a spa with steam
                        and sauna facilities, offering a rejuvenating experience
                        for residents looking to unwind after a busy day.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Fitness Center & Wellness Zone:</strong>A
                        state-of-the-art gym with the latest fitness equipment,
                        a yoga studio, and dedicated wellness spaces to promote
                        health and well-being.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Children’s Play Area:</strong>A secure and
                        creatively designed play zone that allows children to
                        explore and play safely within the complex.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sports Courts:</strong>For residents with a love
                        for sports, GENX PEARL CITY offers a variety of sports
                        facilities, including tennis, badminton, and basketball
                        courts, as well as a jogging track for fitness
                        enthusiasts.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Green Spaces & Landscaped Gardens:</strong>The
                        development includes beautifully landscaped gardens,
                        walking trails, and outdoor recreational areas that
                        create an inviting and relaxing environment for
                        residents to connect with nature.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Retail & Convenience Stores:</strong>The lower
                        levels of each tower will feature retail outlets and
                        convenience stores offering daily necessities, ensuring
                        that residents have easy access to essential services
                        within the premises.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>24/7 Security & Surveillance:</strong>To provide
                        peace of mind, the entire property is equipped with
                        modern security features, including CCTV surveillance,
                        manned security personnel, and controlled entry points.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Smart Home Features:</strong>GENX PEARL CITY
                        integrates advanced technology into each apartment, with
                        smart home systems allowing residents to control
                        lighting, temperature, and security through their
                        smartphones. This technology enhances comfort,
                        convenience, and energy efficiency.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Ample Parking Spaces:</strong>A multi-level
                        parking structure ensures that every resident has access
                        to secure and convenient parking, with additional spaces
                        provided for visitors.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Sustainability:</h4>
                <div>
                  <p>
                    GENX PEARL CITY is committed to sustainable living. The
                    project is designed with energy-efficient systems, including
                    solar power, rainwater harvesting, and waste management
                    systems, making it environmentally friendly. The use of
                    green building materials and the integration of eco-friendly
                    practices in construction and maintenance reduce the carbon
                    footprint of the development, creating a healthier and more
                    sustainable environment for residents.
                  </p>
                   
                </div>

                <h4 className="title-2">Project Timeline:</h4>
                <div>
                  <p>
                    GENX PEARL CITY is slated for phased construction, with the
                    first tower expected to be completed in [insert expected
                    date]. The entire project is projected to be completed and
                    ready for occupancy by [insert expected timeline], ensuring
                    that prospective buyers can begin enjoying their luxurious
                    new homes in a timely manner.
                  </p>
                </div>
               
                
                
               
                
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
               

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
