import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GEN X SILVER CREST</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Kothwan, Near Rupaspur, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  GEN X Silver Crest is an exclusive, state-of-the-art
                  residential development nestled in the prime location of
                  Kothwan, near Rupaspur, off Bailey Road, Patna. This upcoming
                  project promises to redefine the standards of urban living in
                  Patna, offering a unique combination of modern architecture,
                  luxury, and convenience. With a single, elegantly designed
                  residential tower, GEN X Silver Crest is a place where
                  contemporary living meets comfort, sustainability, and style.
                </p>
                <p>
                  Set amidst an emerging urban landscape, this premium
                  residential apartment complex is tailored to cater to the
                  growing demand for high-quality living spaces in Patna.
                  Featuring a limited number of meticulously planned apartments,
                  the project offers a harmonious blend of spacious layouts,
                  world- class amenities, and strategic location advantages.
                </p>

                <h4 className="title-2">Project Highlights:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Single Tower Residential Complex: </strong>
                        The project features a single, thoughtfully designed
                        residential tower to provide residents with a close-knit
                        community atmosphere while maintaining exclusivity.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Spacious Configurations: </strong>
                        The apartments are available in various
                        configurations—2BHK, 3BHK, and 4BHK units—catering to
                        diverse family sizes and preferences.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Modern Amenities: </strong>
                        GEN X Silver Crest comes equipped with cutting-edge
                        amenities to enhance the quality of life, ensuring
                        comfort, security, and a fulfilling lifestyle for all
                        its residents.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Detailed Features & Amenities:</h4>
                <div>
                  <p>
                    <strong>Residential Units:</strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Choice of Apartments: </strong>
                        Spacious 2BHK, 3BHK, and 4BHK apartments designed to
                        meet the needs of modern families. These units feature
                        large windows to maximize natural light, ventilated
                        spaces, and practical layouts.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>High-Quality Finishes: </strong>
                        Every apartment is finished with top-of-the-line
                        materials, including high-quality flooring, modern
                        fittings, and premium fixtures. Modular kitchens, sleek
                        bathrooms, and spacious bedrooms are designed for
                        maximum comfort and style.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Smart Home Features: </strong>
                        Advanced home automation features, including smart
                        lighting, temperature control, and security systems, add
                        a touch of convenience and modernity to everyday living.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Balconies with Views: </strong>
                        Select apartments offer expansive balconies that provide
                        residents with beautiful views of the surrounding area,
                        along with space for outdoor relaxation.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Modern Amenities:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Clubhouse and Recreational Facilities: </strong>
                        <ul>
                          <li>
                            <p>
                              A well-appointed clubhouse with an array of
                              amenities such as a fully-equipped fitness center,
                              indoor games area, and multipurpose hall.
                            </p>
                          </li>
                          <li>
                            <p>
                              The clubhouse serves as the perfect place for
                              social gatherings, events, or a relaxing evening
                              after a busy day.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Landscaped Gardens & Open Spaces: </strong>
                        <ul>
                          <li>
                            <p>
                              Lush green landscapes with beautifully designed
                              gardens and walking paths allow residents to enjoy
                              nature within the comfort of their homes.
                            </p>
                          </li>
                          <li>
                            <p>
                              Open spaces for recreational activities and
                              relaxation, ensuring an improved quality of life.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Children’s Play Area: </strong>
                        <ul>
                          <li>
                            <p>
                              A secure and vibrant play area for children with
                              age-appropriate play equipment, ensuring a fun and
                              safe environment for kids to explore.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Swimming Pool: </strong>
                        <ul>
                          <li>
                            <p>
                              A modern, well-maintained swimming pool with a
                              separate kid’s pool to cater to the residents’
                              fitness and leisure needs.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>24/7 Security and Surveillance: </strong>
                        <ul>
                          <li>
                            <p>
                              High-level security measures, including CCTV
                              surveillance, intercom facilities, and a dedicated
                              security team, ensure the safety of all residents.
                            </p>
                          </li>
                          <li>
                            <p>
                              Gated entry with controlled access to maintain a
                              secure environment.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Power Backup & Water Supply: </strong>
                        <ul>
                          <li>
                            <p>
                              Continuous power backup for common areas,
                              elevators, and select apartments, ensuring
                              uninterrupted services even during power outages.
                            </p>
                          </li>
                          <li>
                            <p>
                              24/7 water supply with modern plumbing
                              infrastructure to ensure convenience and comfort.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Dedicated Parking: </strong>
                        <ul>
                          <li>
                            <p>
                              Ample parking space with reserved parking for
                              residents and additional visitor parking spots for
                              guests, ensuring ease of access and safety.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>
                          Rainwater Harvesting & Waste Management:{" "}
                        </strong>
                        <ul>
                          <li>
                            <p>
                              Sustainable living solutions with an efficient
                              rainwater harvesting system to conserve water
                              resources.
                            </p>
                          </li>
                          <li>
                            <p>
                              Well-designed waste management systems to maintain
                              hygiene and environmental responsibility.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantages:</h4>
                <div>
                  <p>
                    GEN X Silver Crest enjoys a prime location at Kothwan, near
                    Rupaspur, Bailey Road, Patna—one of the city’s most
                    prominent and rapidly developing areas. The project’s
                    location offers excellent connectivity, strategic access to
                    key areas, and a wealth of conveniences right at your
                    doorstep.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Easy Access to Major Roads: </strong>
                        The property is situated just off Bailey Road, one of
                        Patna’s most important arterial roads, providing smooth
                        connectivity to important parts of the city.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Proximity to Key Landmarks: </strong>
                        <ul>
                          <li>
                            <p>
                              <strong>Schools & Colleges:</strong>
                              The area is home to reputed educational
                              institutions such as Patna University, Delhi
                              Public School, and others, making it an ideal
                              location for families with school-going children.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Shopping & Entertainment: </strong>
                              Several malls, shopping centers, and entertainment
                              hubs are easily accessible, making it convenient
                              for residents to shop, dine, and enjoy leisure
                              activities.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Healthcare: </strong>
                              Leading hospitals and medical centers, including
                              PMCH and Indira Gandhi Institute of Medical
                              Sciences, are located within a short distance.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Transportation: </strong>
                              Patna Junction, the city’s main railway station,
                              is within easy reach, and the project benefits
                              from proximity to the Patna Airport, facilitating
                              quick and efficient travel.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Emerging Neighborhood: </strong>
                        The locality around Kothwan and Rupaspur is witnessing
                        rapid infrastructure development, enhancing the value of
                        the property and ensuring future growth potential.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why Invest in Gen X Sliver Crest ?</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Premium Living Experience: </strong>Designed
                        with an emphasis on luxury, functionality, and comfort,
                        GEN X Silver Crest offers a superior living experience
                        that blends urban sophistication with the serenity of
                        suburban living.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Appreciation Potential: </strong>
                        With the growing demand for quality housing in Patna and
                        the strategic location of the project, GEN X Silver
                        Crest promises high long-term value appreciation, making
                        it an ideal investment opportunity.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sustainable Living: </strong>The project
                        incorporates eco-friendly features such as rainwater
                        harvesting and waste management systems, ensuring that
                        residents enjoy a sustainable and responsible lifestyle.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
