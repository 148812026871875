import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ApartmentV2 extends Component {
  render() {
    let CustomClass = this.props.customClass ? this.props.customClass : "";

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <></>
    );
  }
}

export default ApartmentV2;
