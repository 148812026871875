import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV2 from "./components/home-v2";

import PortfolioV2 from "./components/portfolio-v2";

import Faq from "./components/faq";

import Error from "./components/404";
import Location from "./components/location";

import Contact from "./components/contact";

import ContactV2 from "./components/contact2";
import Projects from "./components/projects";
import Project_Details from "./components/project-details";
// import UpcomingProjects from './components/upcoming-projects';
import AboutUs from "./components/about-us";
import PrivacyPolicy from "./components/privacy-policy";
import TermsConditions from "./components/terms";
import OngoingProjects from "./components/ongoing-projects";
import CompletedProjects from "./components/completed-projects";
import UpcomingProjects2 from "./components/upcoming-projects2";
import GenxShivaDetails from "./components/genx-shiva-datails";
import GenXPearlCity from "./components/GenXPearlCity";
import GenXPlatinum from "./components/GenXPlatinum";
import GenXSilver from "./components/GenXSilver";
import GenXCrystal from "./components/GenXCrystal";
import GenXLandmark from "./components/GenXLandmark";
import GenXIcon from "./components/GenXIcon";
import GenXLotus from "./components/GenXLotus";
import GenXNamrata from "./components/GenXNamrata";
import GenXVilla from "./components/GenXVilla";
import GenXArya from "./components/GenXArya";
import GenXRamashram from "./components/GenXRamashram";
import GenXCity from "./components/GenXCity";
import GenXGreen from "./components/GenxGreen";
import GenXOasis from "./components/GenXOasis";
import JKRESIDENCY from "./components/JkResidency";
import GenXCapital from "./components/GenxCapital";

class Root extends Component {
  render() {
    return (
      <Router basename="">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV2} />
            <Route path="/home-v2" component={HomeV2} />

            <Route path="/about-us" component={AboutUs} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-conditions" component={TermsConditions} />

            <Route path="/banking-partner" component={PortfolioV2} />

            <Route path="/faq" component={Faq} />

            <Route path="/404" component={Error} />
            <Route path="/location" component={Location} />

            <Route path="/projects" component={Projects} />
            <Route path="/completed-projects" component={CompletedProjects} />
            <Route path="/ongoing-projects" component={OngoingProjects} />
            <Route path="/upcoming-projects" component={UpcomingProjects2} />

            <Route path="/project-details" component={Project_Details} />
            <Route path="/genx-shiva-details" component={GenxShivaDetails} />
            <Route path="/genx-pearl-details" component={GenXPearlCity} />
            <Route path="/genx-platinum-details" component={GenXPlatinum} />
            <Route path="/genx-silver-details" component={GenXSilver} />
            <Route path="/genx-crystal-details" component={GenXCrystal} />
            <Route path="/genx-landmark-details" component={GenXLandmark} />
            <Route path="/genx-icon-details" component={GenXIcon} />
            <Route path="/genx-lotus-details" component={GenXLotus} />
            <Route path="/genx-namrata-details" component={GenXNamrata} />
            <Route path="/genx-villa-details" component={GenXVilla} />
            <Route path="/genx-arya-details" component={GenXArya} />
            <Route path="/genx-ramashram-details" component={GenXRamashram} />
            <Route path="/genx-city-details" component={GenXCity} />
            <Route path="/genx-green-details" component={GenXGreen} />
            <Route path="/genx-oasis-details" component={GenXOasis} />
            <Route path="/jk-residency-details" component={JKRESIDENCY} />
            <Route path="/genx-capital-details" component={GenXCapital} />

            <Route path="/contact" component={Contact} />
            <Route path="/request-callback" component={ContactV2} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
