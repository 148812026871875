import React from 'react';
import Navbar from './global-components/navbar-v2';
import Aboutv3 from './section-components/about-v3';
import Video from './section-components/video-v2';
import ApartmentV2 from './section-components/apartment-v2';
import Testimonial from './section-components/testimonial-v2';

import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import HomeV2Video from './homeV2/homeV2Video';
import CallToActonV3 from './section-components/call-to-action-v3';
// import Sponsor from './section-components/sponsor-v1';
import Sponsor from './section-components/sponsor-v3';
import CounterV1 from './section-components/counter-v1';
import RunningProjectsV2 from './homeV2/RunningProjectsV2';
import FeaturesH2V1 from './section-components/features-h2v1';
import NavbarV3 from './global-components/navbar-v4';
import HomeV2Focus from './homeV2/homeV2Focus';

const Home_V2 = () => {

    
    return <div>
        {/* <Navbar  /> */}
        <NavbarV3 />
        <HomeV2Video />
        {/* <Banner /> */}
        <Aboutv3 />
        <Video />
        <RunningProjectsV2 />
        {/* <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---"/> */}
        {/* <FeaturesH2V1 customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---"/> */}
        <HomeV2Focus />
        {/* <UpComingProduct /> */}
        <div className='mb-5'>
        <CounterV1 />
        </div>
        
        <ApartmentV2 />
        {/* <ProductSlider />
        <Availability /> */}
        {/* <Neighbour /> */}
        {/* <Cateogory /> */}
        {/* <ServiceV2 /> */}
        <CallToActonV3 />
        <Testimonial />
        <Sponsor />
        
        
        <CallToActionV1 />
        
        <Footer />
    </div>
}

export default Home_V2

