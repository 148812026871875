import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GENX CITY</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX CITY</strong>, a prestigious
                  residential development designed for those who seek a blend of
                  modern luxury, convenience, and a prime location. Located at{" "}
                  <strong>RPS More, Bailey Road</strong>, Patna, this completed
                  single-tower apartment complex offers an unparalleled living
                  experience in one of the city's most sought-after
                  neighborhoods.
                </p>
                <p>
                  <strong>GENX CITY</strong> is a landmark project that combines
                  contemporary architecture with functional living spaces. Its
                  central location ensures residents are at the heart of Patna's
                  vibrant social, educational, and commercial hubs, while the
                  peaceful ambiance of the development allows for a lifestyle
                  that is both dynamic and relaxing.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          Spacious and Thoughtfully Designed Apartments:{" "}
                        </strong>
                        The project offers an assortment of 2 BHK, 3 BHK, and 4
                        BHK apartments, each designed with ample space, modern
                        aesthetics, and the highest quality finishes. Every unit
                        is tailored to maximize natural light and ventilation,
                        providing a serene and comfortable living environment.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>World-Class Amenities: GENX CITY </strong>
                        comes equipped with state-of-the-art amenities to ensure
                        a lifestyle of comfort and convenience. These include:
                        <ul>
                          <li>
                            <p>
                              <strong>24/7 Power Backup </strong>
                              fensuring uninterrupted living
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Parking Space </strong>
                              for residents and visitors
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Security & Safety:</strong>
                        The safety of our residents is a top priority. The
                        building is equipped with 24/7 CCTV surveillance,
                        professional security personnel, gated access, and
                        advanced fire safety systems, ensuring peace of mind at
                        all times.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Sustainability Features: GENX CITY </strong>
                        is designed with eco-conscious living in mind,
                        incorporating energy-efficient lighting, water
                        conservation systems, and green spaces, making it an
                        environmentally friendly choice for modern living.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime Location:</strong>
                        Situated at <strong>RPS More, Bailey Road</strong>, this
                        project enjoys a central location with easy access to
                        Patna's key areas. Whether it’s work, education, or
                        leisure, <strong>GENX CITY</strong> ensures you are
                        always close to everything that matters.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Well-Connected: </strong>
                        Bailey Road is one of Patna's busiest and most connected
                        thoroughfares, providing seamless connectivity to
                        important landmarks, business centers, and
                        transportation hubs. Patna Junction, Patna Airport, and
                        major bus terminals are all just a short drive away.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Nearby Amenities: </strong>The project is in
                        proximity to leading educational institutions like{" "}
                        <strong>RPS School</strong>, renowned hospitals,
                        shopping malls, entertainment centers, and dining
                        options, making it an ideal place for families and
                        professionals alike.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why GENX CITY?</h4>
                <div>
                  <p>
                    <strong>GENX CITY</strong> is more than just a home; it’s a
                    lifestyle. Offering spacious apartments, top-tier amenities,
                    and an exceptional location, this residential tower stands
                    as a symbol of modern urban living in Patna. Whether you are
                    looking for a new home or a strategic investment,{" "}
                    <strong>GENX CITY</strong>
                    offers the perfect balance of luxury, convenience, and
                    practicality.
                  </p>
                  With its unbeatable location on <strong>Bailey Road</strong>,
                  world-class facilities, and a focus on safety and
                  sustainability, <strong>GENX CITY</strong> provides the ideal
                  setting for a vibrant and fulfilling life.
                  <p></p>
                </div>

                <h4 className="title-2">
                  Experience the future of urban living at GENX CITY – Where
                  modernity, convenience, and luxury come together in the heart
                  of Patna.
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
                
                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
