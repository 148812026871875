import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Faq from './section-components/faq-v1';
import Counter from './section-components/counter-v1';

import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';

const FaqV1 = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Frequently asked questions" subheader="FAQ" />
        <Faq />
        <Counter />
 
        <CallToActionV1 />
        <Footer />
    </div>
}

export default FaqV1

