import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>GENX DAFFODILS</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Kothwa, Rupaspur Nahar, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  GENX DAFFODILS is an elegantly designed single-tower apartment
                  complex that showcases cutting- edge architecture and
                  meticulous attention to detail. The project consists of
                  spacious 2BHK and 3BHK apartments that offer ample living
                  space, superior quality construction, and a host of modern
                  amenities, making it the ideal choice for families, young
                  professionals, and investors looking to live or invest in one
                  of Patna’s rapidly developing areas.
                </p>
                <p>
                  <p>
                    {" "}
                    <strong> Key Features & Highlights: </strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Prime Location:</strong>
                    </li>
                    <ul>
                      <li>
                        <strong>Strategic Connectivity:</strong> GENX DAFFODILS
                        is ideally situated in <strong>Kothwa</strong>, one of
                        Patna’s most promising localities, in close proximity to{" "}
                        <strong>Rupaspur Nahar.</strong>. The area is
                        well-connected to major roads and transport links,
                        making it easy to access Patna's city center, key
                        commercial hubs, educational institutions, healthcare
                        centers, and recreational facilities.
                      </li>
                      <li>
                        <strong>Peaceful Surroundings:</strong> Despite being
                        well-connected, the project enjoys a calm and peaceful
                        environment, away from the hustle and bustle of the
                        city, offering residents a serene living experience.
                      </li>
                    </ul>
                  </ol>
                </p>

                <h4 className="title-2">Contemporary Apartment Design:</h4>
                <div>
                  <p>
                    GENX DAFFODILS offers meticulously designed apartments that
                    focus on both aesthetics and functionality. Each unit is
                    optimized to provide maximum space utilization while
                    ensuring a flow of natural light and ventilation.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>2BHK and 3BHK Apartments: </strong>
                        Spacious layouts with well-planned rooms, offering
                        versatile living spaces to suit different family needs.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Modern Interiors: </strong>
                        High-quality finishes with premium flooring, modular
                        kitchens, and contemporary bathroom fittings ensure that
                        every apartment exudes a sense of luxury.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Balconies: </strong>
                        Thoughtfully designed balconies in each apartment
                        provide outdoor space to unwind and enjoy the
                        surrounding views.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">World-Class Amenities:</h4>
                <div>
                  <p>
                    GENX DAFFODILS is equipped with a comprehensive range of
                    <strong>modern amenities</strong> that cater to the diverse
                    needs of its residents. Every aspect of the development has
                    been designed to offer ultimate convenience and comfort.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Fully Equipped Gymnasium: </strong>
                        Stay fit with a state-of-the-art fitness center,
                        equipped with the latest gym equipment for all your
                        workout needs.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Rooftop Garden: </strong>
                        Perched above the bustling city, the Rooftop Garden
                        provides a serene escape, offering residents panoramic
                        views of the skyline and surrounding landscapes.
                        Designed with lush greenery, walking paths, and
                        comfortable seating areas, this space serves as a
                        perfect retreat for relaxation, morning yoga, or
                        informal gatherings.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Community Hall: </strong>
                        The Community Hall is a versatile space that brings
                        residents together for social, cultural, and
                        recreational activities. Whether hosting community
                        meetings, yoga sessions, children’s playgroups, or
                        festive celebrations, the hall is designed to be
                        adaptable for various events.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>
                        The property is equipped with round-the-clock security
                        systems, including CCTV surveillance, well-trained
                        security personnel, and access control at entry and exit
                        points to ensure the safety and peace of mind of all
                        residents.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Power Backup: </strong>
                        Continuous power supply for common areas and apartments
                        ensures there are no interruptions in daily activities.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Parking Space: </strong>
                        The project offers ample parking space for residents and
                        visitors, including designated spots for each apartment.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Sustainable Living:</h4>
                <div>
                  <p>
                    At GENX DAFFODILS, sustainability is at the forefront of
                    design and construction. The project incorporates green
                    initiatives and energy-efficient systems, including:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Rainwater Harvesting: </strong>A rainwater
                        harvesting system to help conserve water and reduce
                        dependency on municipal supplies.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Energy-Efficient Lighting: </strong>
                        LED lighting and energy-efficient appliances are
                        integrated into the design to reduce energy consumption.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Waste Management Systems: </strong>
                        Efficient waste segregation and disposal systems promote
                        an eco-friendly living environment.
                      </p>
                    </li>
                  </ul>
                </div>
             
              
                
                
                
                
               
                
               
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                
               
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
               
                
                
                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> 
               
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
