import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import TermsConditionsSection from './section-components/terms-conditions';

const TermsConditions = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Terms & Conditions" />
     
        <TermsConditionsSection />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default TermsConditions

