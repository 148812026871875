import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>GENX NAMRATA</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX NAMRATA</strong>, an exclusive
                  residential development nestled in the heart of{" "}
                  <strong>RPS More, Bailey Road</strong>, Patna. This completed
                  single-tower apartment complex is designed to offer modern
                  urban living at its finest. With its contemporary
                  architecture, spacious layouts, and world- class amenities,
                  <strong>GENX NAMRATA</strong> is an epitome of comfort,
                  luxury, and convenience.
                </p>
                <p>
                  Perfectly positioned in one of Patna’s most sought-after
                  locations, <strong>GENX NAMRATA</strong> provides easy access
                  to key commercial, educational, and recreational areas, making
                  it the ideal choice for individuals and families looking for a
                  premium living experience in Patna.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Spacious & Modern Apartments: </strong>
                        Offering 2 BHK, 3 BHK, and 4 BHK apartments,{" "}
                        <strong>GENX NAMRATA</strong> is designed to provide a
                        perfect blend of style, comfort, and functionality. Each
                        unit features open, well-ventilated spaces, high-quality
                        interiors, and modern finishes, ensuring that your
                        living experience is both comfortable and luxurious.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Premium Amenities: </strong>
                        To enhance your quality of life, GENX NAMRATA comes
                        equipped with a range of top-notch amenities, including:
                        <ul>
                          <li>
                            <p>
                              <strong>Children’s Play Area </strong>
                              designed to ensure safety and enjoyment for kids
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Landscaped Gardens </strong>
                              offering a peaceful environment for relaxation
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>24/7 Power Backup </strong>
                              for uninterrupted living
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Sufficient Parking Space </strong>
                              for residents and guests
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Security and Safety: </strong>
                        The safety of residents is paramount at{" "}
                        <strong>GENX NAMRATA</strong>. The development is
                        equipped with:
                        <ul>
                          <li>
                            <p>
                              <strong>24/7 CCTV Surveillance </strong>
                              across common areas and entrances
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Manned Security Staff </strong>
                              ensuring constant vigilance and secure access
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Smart Access Control </strong>
                              for residents safety
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Fire Safety Systems </strong>
                              in place for emergency preparedness
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Eco-Friendly Features: </strong>
                        Designed with sustainability in mind,{" "}
                        <strong>GENX NAMRATA</strong>
                        incorporates energy-efficient lighting, water
                        conservation systems, and green spaces, creating an
                        environmentally responsible living space that promotes a
                        greener lifestyle.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime Location on Bailey Road: </strong>
                        <strong>GENX NAMRATA</strong> enjoys a coveted location
                        at <strong>RPS More, Bailey Road</strong>, , one of
                        Patna's busiest and most well-connected corridors. This
                        strategic location offers easy access to the city’s top
                        commercial, educational, and healthcare hubs.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Easy Connectivity: </strong>
                        The development is well-connected to major
                        transportation hubs, including{" "}
                        <strong>Patna Junction, Patna Airport</strong>, and key
                        bus terminals, ensuring easy travel across the city and
                        beyond. Public transportation is readily available,
                        making daily commuting a breeze.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Proximity to Key Landmarks: GENX NAMRATA{" "}
                        </strong>{" "}
                        is located in close proximity to renowned educational
                        institutions such as{" "}
                        <strong>RPS School, St. Xavier’s College</strong>, and{" "}
                        <strong>Patna University</strong>, as well as top
                        medical centers like <strong>Arya Hospital</strong> and{" "}
                        <strong>NMCH</strong>, making it a perfect location for
                        families and professionals alike.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping & Leisure:</strong> With shopping
                        malls, restaurants, cafes, and entertainment centers
                        nearby, <strong>GENX NAMRATA</strong> offers easy access
                        to all the lifestyle amenities one could need. Whether
                        it's shopping, dining, or unwinding with family and
                        friends, everything is just a stone’s throw away.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why Choose GENX NAMRATA?</h4>
                <div>
                  <p>
                    <strong>GENX NAMRATA</strong> offers the ideal blend of luxury, convenience,
                    and a strategic location. With spacious apartments, premium
                    amenities, and a focus on security and sustainability, it is
                    designed to provide an elevated lifestyle for its residents.
                    Whether you are looking for your dream home or a valuable
                    investment opportunity, <strong>GENX NAMRATA</strong> promises to deliver the
                    best in modern living in Patna.
                  </p>
                </div>

                <h4 className="title-2">
                Live in the lap of luxury at GENX NAMRATA – The perfect place where elegance, convenience, and
                comfort converge in the heart of Patna.
                </h4>
                
              

              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              
                
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
                
                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
