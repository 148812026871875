import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class SponsorV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__brand-logo-area ltn__brand-logo-1 pt-80--- pb-110 plr--9">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Banking Partner
              </h6>
              <h1 className="section-title">Banking Partner</h1>
            </div>
          </div>
          <div className="row ltn__brand-logo-active">
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img
                  src={publicUrl + "assets/img/brand-logo/icici1.png"}
                  alt="Brand Logo"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img
                  src={publicUrl + "assets/img/brand-logo/sbi1.png"}
                  alt="Brand Logo"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img
                  src={publicUrl + "assets/img/brand-logo/axis1.png"}
                  alt="Brand Logo"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img
                  src={publicUrl + "assets/img/brand-logo/hdfc1.png"}
                  alt="Brand Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SponsorV3;
