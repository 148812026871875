import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>GENX VILLA</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX VILLA</strong>, a prestigious
                  residential development located at the prime address of{" "}
                  <strong>RPS More, Bailey Road</strong>, Patna. This completed
                  single-tower apartment complex offers a perfect fusion of
                  luxury, modern design, and strategic location, making it one
                  of the most sought-after living destinations in the city. With
                  thoughtfully designed living spaces, world-class amenities,
                  and an unbeatable location, <strong>GENX VILLA</strong>
                  promises a lifestyle of convenience, comfort, and elegance.
                </p>
                <p>
                  Whether you're a growing family, a professional seeking easy
                  access to work and leisure, or an investor looking for a
                  premium property, <strong>GENX VILLA</strong> offers the
                  perfect home for those who appreciate quality living in the
                  heart of Patna.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          Spacious and Elegant Apartments: GENX VILLA{" "}
                        </strong>
                        offers a range of 2 BHK, 3 BHK, and 4 BHK apartments
                        that are designed to maximize space, light, and air
                        circulation. The well-crafted interiors feature high-end
                        finishes, contemporary fixtures, and thoughtful layouts
                        to ensure a blend of comfort and luxury. Each apartment
                        is designed to provide an exceptional living experience.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Premium Amenities: </strong>
                        Designed with modern living in mind,{" "}
                        <strong>GENX VILLA</strong> offers an array of premium
                        amenities:
                        <ul>
                          <li>
                            <p>
                              <strong>24/7 Power Backup </strong>
                              to ensure uninterrupted comfort
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Parking Spaces </strong>
                              for both residents and visitors
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Security and Safety: </strong>
                        Your safety is our priority. <strong>
                          GENX VILLA
                        </strong>{" "}
                        comes with a host of security features:
                        <ul>
                          <li>
                            <p>
                              <strong>24/7 CCTV Surveillance </strong>
                              throughout the premises
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Professional Security Staff </strong>
                              for constant monitoring
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Smart Access Control </strong>
                              ensuring secure entry and exit
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Fire Safety Systems </strong>
                              with the latest technology for your protection
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Sustainability: </strong>
                        Committed to sustainable living,{" "}
                        <strong>GENX VILLA</strong> incorporates eco-friendly
                        features such as energy-efficient lighting, water
                        conservation systems, and green landscaping, providing
                        an environmentally responsible living experience.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime Location on Bailey Road: </strong>
                        The project offers easy access to key transportation
                        hubs such as{" "}
                        <strong>Patna Junction, Patna Airport</strong>, and
                        major bus terminals, making commuting effortless. Public
                        transport options are plentiful, ensuring seamless
                        connectivity within Patna.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Proximity to Key Landmarks: GENX VILLA </strong>
                        is located close to top schools, colleges, hospitals,
                        shopping malls, and entertainment hubs, offering
                        everything you need within reach. Reputed institutions
                        like <strong>RPS School, Arya Hospital</strong>, and{" "}
                        <strong>Patna Medical College</strong> are just minutes
                        away.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping and Entertainment: </strong>The
                        locality is surrounded by shopping malls, restaurants,
                        cafes, and entertainment centers, giving residents
                        plenty of options to relax and enjoy their free time.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why GENX VILLA?</h4>
                <div>
                  <p>
                    <strong>GENX VILLA</strong> offers a unique combination of
                    contemporary living, luxurious amenities, and a strategic
                    location. Whether you're looking for your dream home or an
                    investment that promises value, <strong>GENX VILLA</strong>{" "}
                    provides an unmatched living experience in Patna. With
                    spacious apartments, world-class facilities, and proximity
                    to essential services, <strong>GENX VILLA</strong> is the
                    perfect place for those who aspire to live a life of
                    comfort, convenience, and luxury.
                  </p>
                </div>

                <h4 className="title-2">
                  Live the life you deserve at GENX VILLA – Where luxury,
                  comfort, and convenience come together in the heart of Patna.
                </h4>
               
               
              
               
               
             
                
                
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                
               
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
                {/* Top Rated Product Widget */}
                
               

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
               
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
