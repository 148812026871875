import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GENX Landmark</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  <strong>GENX LANDMARK</strong> is a luxurious{" "}
                  <strong>four-tower residential development</strong>, offering
                  a collection of spacious and thoughtfully crafted{" "}
                  <strong>2BHK, 3BHK, and 4BHK apartments</strong>. The project
                  is designed to provide a superior living experience for modern
                  families, young professionals, and investors. Every detail,
                  from the architectural design to the high-end finishes, is
                  meticulously planned to offer the utmost in comfort, style,
                  and convenience.
                </p>
                <p>
                  With its strategic location at RPS T-Point, just a stone’s
                  throw from RPS More, residents can enjoy the best of both
                  worlds: a peaceful and serene living environment paired with
                  easy access to Patna’s key commercial, educational, and
                  recreational hubs.
                </p>
                <p>
                  <p>
                    {" "}
                    <strong> Key Features & Highlights: </strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Prime Location:</strong>
                    </li>
                    <ul>
                      <li>
                        <p>
                          <strong>Strategic Connectivity: </strong> Located at{" "}
                          <strong>RPS T-Point </strong>, near{" "}
                          <strong>RPS More </strong>, GENX LANDMARK enjoys
                          exceptional connectivity to key areas in Patna,
                          including major business districts, educational
                          institutions, healthcare centers, and shopping
                          destinations. The proximity to vital roads ensures
                          smooth access to the city's arterial routes, making
                          commuting effortless.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Vibrant Neighborhood: </strong>The area around
                          <strong> RPS T-Point </strong> is rapidly developing,
                          with ample green spaces and modern infrastructure,
                          making it one of the most desirable residential
                          addresses in Patna. It is also well-connected by
                          public transportation, enhancing convenience for
                          residents.
                        </p>
                      </li>
                    </ul>
                  </ol>
                </p>

                <h4 className="title-2">
                  World-Class Architecture and Design:
                </h4>
                <div>
                  <p>
                    GENX LANDMARK’s four stunning towers are designed to provide
                    residents with a spacious and airy living environment,
                    featuring:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>2BHK, 3BHK, and 4BHK Apartments: </strong>
                        Large, open-floor plans designed to suit different
                        family sizes and lifestyles. With options ranging from
                        cozy 2BHK units to expansive 4BHK homes, there’s an
                        ideal living space for everyone.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Contemporary Interiors: </strong>
                        The apartments are equipped with the finest finishes,
                        including{" "}
                        <strong>
                          premium vitrified tiles, modular kitchens, high-end
                          bathroom fittings{" "}
                        </strong>
                        , and <strong>designer wardrobes</strong>, offering a
                        blend of luxury and functionality.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Balconies with Scenic Views: </strong>
                        Each apartment comes with spacious balconies that open
                        to sweeping views of the surrounding landscape and city
                        skyline, providing a perfect place to unwind or
                        entertain guests.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">World-Class Amenities:</h4>
                <div>
                  <p>
                    GENX LANDMARK offers a wide range of modern amenities
                    designed to enhance your lifestyle, ensuring comfort,
                    convenience, and relaxation for every resident.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Clubhouse: </strong>A grand clubhouse for
                        socializing, hosting events, and relaxing, featuring a
                        lounge, multipurpose hall, and recreational spaces.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>State-of-the-Art Fitness Center: </strong>
                        Fully equipped gymnasium with the latest workout
                        machines and fitness equipment to maintain a healthy
                        lifestyle.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Swimming Pool: </strong>A luxurious swimming
                        pool with a dedicated lounge area, ideal for relaxation
                        and rejuvenation.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Children’s Play Area: </strong>A secure,
                        fun-filled play zone where children can enjoy their
                        outdoor activities safely.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>A secure, The entire
                        property is under constant surveillance, with
                        round-the-clock security personnel, CCTV cameras, and
                        controlled access points to ensure the safety and
                        privacy of residents.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Power Backup: </strong>Uninterrupted power
                        supply with 24/7 backup for all common areas and
                        apartments, ensuring comfort at all times.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>High-Speed Elevators: </strong>Modern,
                        high-speed lifts in each tower for quick and convenient
                        movement between floors.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Ample Parking: </strong>Spacious, multi-level
                        parking facilities with dedicated spaces for residents
                        and visitors.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Sustainability and Green Living:</h4>
                <div>
                  <p>
                    At GENX LANDMARK, we are committed to creating a greener,
                    more sustainable living environment:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Rainwater Harvesting: </strong>Advanced
                        rainwater harvesting systems to conserve water and
                        support environmental sustainability.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Energy-Efficient Design: </strong>
                        The project is designed with eco-friendly materials and
                        energy- efficient lighting to reduce carbon footprint.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Waste Management System: </strong>A well-planned
                        waste management system that promotes recycling and
                        reduces environmental impact.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Luxury Features:</h4>
                <div>
                  <p>
                    GENX LANDMARK is designed to offer a lifestyle of
                    unparalleled luxury and comfort:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Smart Home Features: </strong>Some units come
                        with pre-installed smart home features, such as
                        automated lighting, climate control, and security
                        systems, giving you full control over your living space.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>High-Quality Finishes: </strong>The interiors
                        feature high-end flooring, modern fixtures, and premium
                        paints that create an elegant ambiance throughout the
                        home.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Landscaped Gardens:</strong>Beautifully
                        landscaped gardens and walking paths offer a serene
                        outdoor space for relaxation and rejuvenation.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Proximity to Key Locations:</h4>
                <div>
                  <p>
                    GENX LANDMARK is strategically located to offer easy access
                    to Patna’s important destinations:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Educational Institutions: </strong>Top schools,
                        colleges, and universities such as [insert names of
                        nearby schools/colleges].
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Healthcare: </strong>
                        Leading hospitals and medical centers like [insert names
                        of nearby hospitals].
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping and Entertainment: </strong>Close to
                        prominent malls, retail outlets, restaurants, and
                        entertainment zones.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Transportation: </strong>Excellent road
                        connectivity to Patna’s major hubs, as well as easy
                        access to public transport services.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Investment Potential:</h4>
                <div>
                  <p>
                    GENX LANDMARK offers not only a luxurious living experience
                    but also a highly promising investment opportunity. Located
                    in an area of rapid urban growth, with significant
                    commercial and infrastructural developments underway, the
                    property promises excellent long-term appreciation
                    potential. This makes it a perfect choice for investors
                    looking for a combination of luxury and high returns.
                  </p>
                  <p>
                    <strong>Why Choose GENX LANDMARK?</strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Luxury and Comfort:</strong>Offering top-tier
                        amenities, sophisticated designs, and spacious
                        apartments, GENX LANDMARK is the epitome of modern
                        luxury living.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Strategic Location: </strong>
                        Situated in one of the most coveted and rapidly
                        developing areas of Patna, offering excellent
                        connectivity and future growth prospects.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sustainability: </strong>Green features and
                        eco-friendly designs ensure that residents live in
                        harmony with nature, without compromising on luxury.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Comprehensive Amenities: </strong>From fitness
                        to relaxation, every aspect of your lifestyle is catered
                        to, making this project ideal for families and
                        individuals alike.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>High-Value Investment: </strong>With its
                        location and luxury offerings, GENX LANDMARK presents a
                        high-value investment opportunity with strong future
                        potential.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">
                  Elevate Your Living Experience – Welcome to GENX LANDMARK!
                </h4>
                <div>
                  <p>
                    GENX LANDMARK is more than just a residence; it’s a
                    lifestyle that blends comfort, luxury, and convenience in
                    the heart of Patna. Whether you are looking for a spacious
                    home for your family or a lucrative investment, GENX
                    LANDMARK is designed to meet all your needs and exceed your
                    expectations.
                  </p>
                </div>

                <h4 className="title-2">
                  Book your dream home today and experience the best that luxury
                  living has to offer!
                </h4>
                <div>
                  <p>
                    For more information or to schedule a visit, contact us now.
                  </p>
                </div>
               
            
                
                
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
               
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
