import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import PortfolioV1 from './section-components/portfolio-v3';
import CallToActonV3 from './section-components/call-to-action-v3';


import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';

const PortfolioV2 = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Banking Partner" subheader="Banking Partner" />
        <PortfolioV1 />
        <CallToActonV3 />
        

        <div className='mt-5'>
        <CallToActionV1 />
        </div>
        
        
        <Footer />
    </div>
}

export default PortfolioV2

