import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>GENX RAMASHRAM</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Seikhpura More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX RAMASHRAM</strong>, a premium
                  residential development that redefines modern living in the
                  heart of Patna. Strategically located at the prime locale of{" "}
                  <strong>Seikhpura More, Bailey Road</strong>, this iconic
                  single-tower apartment complex offers a perfect blend of
                  contemporary design, luxury, and convenience.
                </p>
                <p>
                  Nestled in one of the most sought-after addresses in Patna,
                  <strong>GENX RAMASHRAM</strong> provides seamless connectivity
                  to major hubs of the city, including commercial centers,
                  schools, hospitals, shopping districts, and entertainment
                  zones, making it an ideal choice for families and
                  professionals alike.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Premium Living Spaces: </strong>
                        The project comprises spacious, well-planned 2 BHK, 3
                        BHK, and 4 BHK apartments designed to provide an
                        exceptional standard of living. Every unit is crafted
                        with attention to detail, featuring modern fixtures and
                        superior finishes to ensure comfort and elegance.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Modern Amenities: </strong>
                        The residents of <strong>GENX RAMASHRAM</strong> enjoy
                        an array of top-notch amenities such as a
                        state-of-the-art fitness center, a dedicated children's
                        play area, landscaped gardens, a multipurpose hall, and
                        24/7 power backup. The development also includes ample
                        parking space, ensuring convenience for all residents.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Security & Safety: </strong>
                        Equipped with round-the-clock security surveillance,
                        smart access control systems, fire safety measures, and
                        emergency evacuation plans,{" "}
                        <strong>GENX RAMASHRAM</strong> ensures the utmost
                        safety of its residents.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Eco-friendly Features: </strong>
                        The development incorporates sustainable design
                        practices, including rainwater harvesting,
                        energy-efficient lighting, and waste management systems,
                        contributing to a greener and more eco-conscious living
                        environment.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Vibrant Community: </strong>
                        With a blend of modern living and a strong sense of
                        community, <strong>GENX RAMASHRAM</strong> is the
                        perfect place to forge lasting relationships and enjoy a
                        fulfilling lifestyle.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime Accessibility:</strong>
                        The project enjoys a prime location on{" "}
                        <strong>Bailey Road</strong>, one of the most
                        well-connected roads in Patna, ensuring quick access to
                        important landmarks and institutions.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Connectivity: </strong>
                        Just minutes away from{" "}
                        <strong>Patna Junction, Patna Airport</strong>, and
                        major bus terminals, providing seamless connectivity to
                        other parts of the city and beyond.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Proximity to Key Areas: </strong>Schools,
                        colleges, healthcare facilities, retail outlets, and
                        entertainment hubs are all within close reach, making
                        daily living incredibly convenient.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why GENX RAMASHRAM?</h4>
                <div>
                  <p>
                    <strong>GENX RAMASHRAM</strong> isn't just a place to live; it’s a
                    lifestyle choice. With its blend of luxury, modernity, and
                    strategic location, this residential tower offers everything
                    you need for a comfortable and fulfilling life. Whether
                    you're a first-time homebuyer or looking for an upgrade,
                    <strong>GENX RAMASHRAM</strong> is the perfect place to call home.
                  </p>
                </div>
            
               
                
               
                
               
               
              
               
             
                
                
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
              

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
