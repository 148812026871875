import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>GENX OASIS</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX OASIS</strong>, an exquisite
                  residential development that offers a serene living experience
                  right in the heart of Patna. Located at the prime junction of{" "}
                  <strong>RPS More, Bailey Road</strong>, this completed
                  single-tower apartment complex redefines modern urban living.
                  Designed to provide a perfect balance between comfort, luxury,
                  and convenience, <strong>GENX OASIS</strong> is a true retreat
                  in the bustling city of Patna.
                </p>
                <p>
                  With its contemporary architecture, spacious apartments, and
                  world-class amenities, <strong>GENX OASIS</strong> is the
                  ideal choice for individuals and families looking to enjoy an
                  elevated lifestyle. Whether you are a professional seeking
                  easy access to the city's commercial hubs or a family in
                  search of a peaceful haven, this project offers everything you
                  need to live life to the fullest.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          Elegant and Spacious Apartments: GENX OASIS{" "}
                        </strong>
                        features a selection of 2 BHK, 3 BHK, and 4 BHK
                        apartments, each thoughtfully designed to optimize
                        space, light, and ventilation. The interiors are
                        finished with high-quality materials, creating a warm,
                        stylish, and functional living space that reflects
                        modern tastes and comfort.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Top-Notch Amenities: </strong>
                        To ensure a luxurious and convenient lifestyle,{" "}
                        <strong>GENX OASIS</strong> offers an impressive range
                        of amenities, including:
                      </p>
                      <ul>
                        <li>
                          <p>
                            <strong>24/7 Power Backup </strong>
                            to ensure uninterrupted living
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Parking Space </strong>
                            for residents and visitors
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <p>
                        <strong>Enhanced Security: GENX OASIS </strong>
                        is equipped with advanced security systems including
                        24/7 surveillance, round-the-clock manned security,
                        smart access control, and fire safety measures, ensuring
                        a safe and secure living environment for all its
                        residents.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Eco-Friendly Features: </strong>
                        Designed with sustainability in mind, the development
                        incorporates eco-friendly initiatives such as
                        energy-efficient lighting, water-saving systems, and
                        green landscapes, making <strong>GENX OASIS</strong> an
                        environmentally responsible choice for modern living.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime and Central Location: </strong>
                        Situated at{" "}
                        <strong>RPS More, Bailey Road, GENX OASIS</strong>{" "}
                        enjoys one of Patna's most coveted addresses. With easy
                        access to important commercial, residential, and
                        educational hubs, it offers unparalleled connectivity.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Proximity to Key Landmarks: </strong>
                        Located just minutes away from{" "}
                        <strong>Patna Junction, Patna Airport</strong>, and
                        major bus terminals, <strong>GENX OASIS</strong> ensures
                        quick and easy access to the entire city.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Nearby Educational and Healthcare Facilities:{" "}
                        </strong>
                        Renowned schools, colleges, and hospitals like{" "}
                        <strong>RPS School, Arya Hospital</strong>, and{" "}
                        <strong>NMCH</strong> are all within close proximity,
                        making it an ideal choice for families.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Convenient Shopping and Leisure: </strong>
                        shopping malls, retail outlets, restaurants, and
                        entertainment hubs are just around the corner, making
                        daily living both convenient and enjoyable.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why GENX OASIS?</h4>
                <div>
                  <p>
                    <strong>GENX OASIS</strong> offers more than just a home —
                    it’s a lifestyle. Whether you're looking for a peaceful
                    refuge from the city's hustle or seeking a home that
                    offers easy access to everything Patna has to offer,{" "}
                    <strong>GENX OASIS</strong> is the ultimate choice. With its
                    thoughtful design, luxurious amenities, and strategic
                    location on <strong>Bailey Road</strong>, it caters to those
                    who value both convenience and quality of life.
                  </p>
                </div>

                <h4 className="title-2">
                  Discover your perfect home at GENX OASIS – A peaceful retreat
                  in the heart of Patna, where luxury meets convenience.
                </h4>
               
                
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
               
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
                

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
