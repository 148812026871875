import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GENX Icon</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Mangalam Colony More, Nearby Saguna More, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  <strong>GENX ICON</strong> is a<strong>single-tower </strong>
                  mixed-use development that combines luxury{" "}
                  <strong>residential apartments</strong> with premium{" "}
                  <strong>commercial spaces</strong>. Whether you are looking
                  for a home that offers comfort and sophistication or a
                  business space with high visibility and connectivity, GENX
                  ICON is your ultimate destination. The project offers
                  meticulously designed{" "}
                  <strong>2BHK and 3BHK residential apartments</strong>, along
                  with <strong>commercial offices and retail spaces</strong>,
                  all in one integrated development.
                </p>
                <p>
                  The modern architecture, world-class amenities, and strategic
                  location make GENX ICON the ideal choice for entrepreneurs,
                  professionals, and families seeking convenience, connectivity,
                  and luxury in Patna.
                </p>
                <p>
                  <p>
                    {" "}
                    <strong> Key Features & Highlights: </strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Prime Location:</strong>
                    </li>
                    <ul>
                      <li>
                        <p>
                          <strong>Unmatched Connectivity: </strong> Located at
                          <strong> Mangalam Colony More</strong>, near{" "}
                          <strong>Saguna More</strong>, GENX ICON is in the
                          heart of Patna’s rapidly developing commercial and
                          residential hub. The location offers easy access to
                          key areas of the city, including business districts,
                          educational institutions, hospitals, and entertainment
                          zones.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Easy Access to Major Roads:: </strong>With
                          easy connectivity to{" "}
                          <strong>Patna-Gaya Road, Bailey Road</strong>, and
                          other major arterial roads, residents and businesses
                          enjoy smooth commuting to important locations like
                          <strong>Patna Junction, Patna Airport</strong>, and{" "}
                          <strong>IT hubs</strong>.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>High Visibility: </strong>The project enjoys
                          prime visibility and foot traffic, making it ideal for
                          businesses, with an opportunity to tap into the city’s
                          growing commercial sector.
                        </p>
                      </li>
                    </ul>
                  </ol>
                </p>

                <h4 className="title-2">Innovative Mixed-Use Design:</h4>
                <div>
                  <p>
                    GENX ICON is a unique commercial-cum-residential development
                    designed to cater to both personal and professional needs
                    under one roof. It offers:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Residential Apartments: </strong>
                        Spacious, well-designed <strong>
                          2BHK and 3BHK
                        </strong>{" "}
                        apartments that offer modern living spaces with premium
                        finishes. Each apartment features an open-plan layout,
                        large windows for natural light, and ample ventilation
                        to create a welcoming and comfortable environment.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Commercial Spaces: </strong>
                        Designed to cater to the needs of businesses, the
                        commercial sections of GENX ICON provide premium office
                        spaces and retail units, ensuring that entrepreneurs and
                        businesses thrive in a dynamic and high-traffic
                        location. These spaces are perfect for offices,
                        showrooms, clinics, retail stores, or cafes.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">World-Class Amenities:</h4>
                <div>
                  <p>
                    GENX ICON offers an array of modern amenities to enhance
                    both residential and business lifestyles:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Business Lounge & Meeting Rooms: </strong>
                        Fully-equipped spaces for meetings, conferences, and
                        networking, ideal for residents working from home or
                        businesses operating in the commercial section.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>
                        Comprehensive security systems including CCTV
                        surveillance, security personnel, and access-controlled
                        entry points ensure the safety and privacy of all
                        residents and businesses.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Elevators: </strong>High-speed elevators to
                        provide quick access to residential floors and
                        commercial spaces.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Parking: </strong>Dedicated parking for both
                        residents and visitors, including spaces for commercial
                        units, making it convenient for business owners and
                        clients alike.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Landscaped Green Spaces: </strong>Enjoy
                        beautifully landscaped gardens and open spaces,
                        providing a tranquil environment amidst the bustling
                        city.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Sustainable and Green Living:</h4>
                <div>
                  <p>
                    GENX ICON incorporates sustainable features that promote
                    eco-friendly living and reduce environmental impact:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Rainwater Harvesting: </strong>A rainwater
                        harvesting system to conserve water and reduce
                        dependency on external water sources.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Energy Efficiency: </strong>
                        The building is designed with energy-efficient systems,
                        including LED lighting, energy-saving appliances, and
                        natural ventilation to minimize electricity consumption.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Waste Management: </strong>A dedicated waste
                        management system ensures proper segregation, recycling,
                        and disposal of waste, contributing to a cleaner,
                        greener environment.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Proximity to Key Locations:</h4>
                <div>
                  <p>
                    GENX ICON is strategically located, offering easy access to
                    essential services and key landmarks in Patna:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Educational Institutions: </strong>Leading
                        schools and colleges, such as [insert names of nearby
                        schools/colleges], are within close proximity, making it
                        convenient for families with children.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Healthcare: </strong>Top medical facilities like
                        [insert names of nearby hospitals], ensuring that
                        healthcare is easily accessible.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping and Entertainment:</strong>Close to
                        malls, restaurants, shopping streets, and entertainment
                        hubs, such as [insert names of nearby malls], allowing
                        residents to enjoy a vibrant and fulfilling lifestyle.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Transport Connectivity:</strong>Excellent access
                        to public transport options, including buses and
                        auto-rickshaws, with major roads linking to Patna
                        Junction and Patna Airport just a short drive away.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Investment Potential:</h4>
                <div>
                  <p>
                    GENX ICON presents an excellent investment opportunity for
                    both residential and commercial purposes. The growing demand
                    for high-quality residential properties and commercial
                    spaces in this prime area ensures that both residents and
                    investors will benefit from long-term value appreciation.
                    The combination of luxury apartments and prime commercial
                    spaces makes it an attractive option for a wide range of
                    buyers.
                  </p>
                  <p>
                    <strong>Why Choose GENX ICON?</strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime Location: </strong>Located at Mangalam
                        Colony More, near Saguna More, one of Patna’s most
                        accessible and rapidly developing areas, GENX ICON
                        offers both convenience and high visibility for
                        businesses.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Perfect Blend of Work & Life: </strong>
                        With integrated residential and commercial spaces, GENX
                        ICON provides the perfect balance of work and lifestyle.
                        Whether you’re looking for a home or a business address,
                        this project has it all.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Modern Design & High-Quality Construction:{" "}
                        </strong>
                        The project is designed with the highest standards of
                        construction and finishes, offering both luxury and
                        durability.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Comprehensive Amenities: </strong>From fitness
                        facilities to business lounges, and 24/7 security, GENX
                        ICON offers everything you need for a modern,
                        convenient, and secure lifestyle.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sustainable Living: </strong>Eco-friendly design
                        elements ensure that the property promotes
                        sustainability while offering modern comfort.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">
                  Live and Work in Style at GENX ICON!
                </h4>
                <div>
                  <p>
                    GENX ICON is more than just a place to live or work – it’s
                    an experience that combines the best of both worlds. Whether
                    you're looking to set up your business in a high-traffic
                    area or create a sophisticated home in the heart of Patna,
                    this iconic development offers everything you need for a
                    modern, comfortable, and convenient lifestyle.
                  </p>
                </div>

                <h4 className="title-2">
                  Book your dream apartment or business space today and be part
                  of Patna’s most exciting new landmark – GENX ICON!
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
