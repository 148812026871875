import React from 'react';
import PageHeader from './global-components/page-header';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import GenXLotusSection from './shop-components/genx-lotus-details';
import ProductSliderV1 from './shop-components/product-slider-v1';

const GenXLotus = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Completed Projects" />
        <ProductSliderV1 />
        <GenXLotusSection />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default GenXLotus

