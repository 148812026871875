import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "./images.css";

class HomeV2Focus extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <div className="ltn__product-slider-area ltn__product-gutter pt-10 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Our Services
                  </h6>
                  <h1 className="section-title">Our Main Focus</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__product-slider-item-three-active slick-arrow-1">
              {/* ltn__product-item */}
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="ltn__product-item ltn__product-item-4 text-center---">
                  <div className="product-img go-top">
                    <img
                      src={publicUrl + "assets/img/product-3/1.avif"}
                      alt="#"
                    />
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge bg-green">Residential</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* ltn__product-item */}
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="ltn__product-item ltn__product-item-4 text-center---">
                  <div className="product-img go-top">
                    <img
                      src={publicUrl + "assets/img/product-3/2.avif"}
                      alt="#"
                    />
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge bg-green---">Commercial</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* ltn__product-item */}
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="ltn__product-item ltn__product-item-4 text-center---">
                  <div className="product-img go-top">
                    <img
                      src={publicUrl + "assets/img/product-3/plot.png"}
                      alt="#"
                    />
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge bg-green">Industrial</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeV2Focus;
