import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GENX SHIVA</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Kolhua, Bairiya, Golamber, Muzaffarpur,
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  <strong>GENX SHIVA</strong> is an elegant single-tower
                  residential complex that combines world-class design with the
                  latest modern amenities. The development features spacious{" "}
                  <strong>2BHK and 3BHK apartments</strong>
                  equipped with high-end finishes and contemporary features.
                  With a focus on sustainability, functionality, and aesthetics,
                  this project offers an ideal environment for families,
                  professionals, and investors alike.
                </p>
                <p>
                  <p>
                    {" "}
                    <strong> Key Features & Highlights: </strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Prime Location:</strong>
                    </li>
                    <ul>
                      <li>
                        <strong>Strategic Position: </strong> GENX SHIVA is
                        ideally located in <strong>Kolhua</strong>, one of
                        Muzaffarpur’s rapidly developing areas, and is in close
                        proximity to the bustling{" "}
                        <strong>Bairiya Golamber</strong>. This prime location
                        offers excellent connectivity to key parts of the city,
                        including commercial zones, educational institutions,
                        healthcare facilities, and entertainment areas.
                      </li>
                      <li>
                        <strong>Tranquil Surroundings:</strong> While being
                        well-connected, the property is located in a peaceful
                        neighborhood, offering a perfect balance of urban
                        accessibility and serene living. The surrounding
                        environment is perfect for those who prefer a quiet,
                        green setting.
                      </li>
                    </ul>
                  </ol>
                </p>

                <h4 className="title-2">Modern Apartment Design:</h4>
                <div>
                  <p>
                    GENX SHIVA boasts spacious, well-thought-out floor plans
                    that cater to the diverse needs of today’s homebuyers. Each
                    apartment has been designed with an emphasis on comfort,
                    practicality, and aesthetics.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>2BHK and 3BHK Units: </strong>
                        Thoughtfully designed layouts that provide ample space
                        for living, dining, and recreational activities. Each
                        unit offers large windows for ample natural light and
                        ventilation.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Stylish Interiors: </strong>
                        The apartments come with premium-quality flooring,
                        modular kitchen setups, and elegant bathroom fittings.
                        Designed to offer modern comfort, every detail reflects
                        quality craftsmanship.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Balconies & Views: </strong>
                        Every apartment features a well-designed balcony,
                        offering beautiful views of the surroundings, perfect
                        for relaxing or enjoying a cup of coffee.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">World-Class Amenities:</h4>
                <div>
                  <p>
                    GENX SHIVA is packed with top-of-the-line amenities that
                    ensure a luxurious and convenient living experience.
                    Designed to cater to the needs of every family member, the
                    project provides both recreational and essential facilities.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Intercom: </strong>
                        This Intercom System is an essential amenity that
                        reflects the growing demand for smart, secure, and
                        convenient living solutions, providing residents with a
                        modern, high-tech environment where communication and
                        safety are top priorities.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>
                        The project ensures the safety and security of residents
                        with round-the-clock surveillance, trained security
                        personnel, and access-controlled entry points.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Power Backup: </strong>
                        Reliable power backup for common areas and apartments,
                        ensuring uninterrupted daily life.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Parking: </strong>
                        Spacious parking facilities with designated spots for
                        residents and visitors, ensuring convenience for all.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Sustainable Living:</h4>
                <div>
                  <p>
                    GENX SHIVA incorporates eco-friendly features and
                    sustainable design practices to ensure a greener future for
                    its residents.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Rainwater Harvesting: </strong>A rainwater
                        harvesting system to reduce water wastage and promote
                        sustainable water usage.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Energy Efficiency: </strong>
                        The development is designed to optimize energy
                        consumption, with LED lighting, energy-efficient
                        appliances, and eco-friendly materials.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Waste Management: </strong>A dedicated waste
                        management system for the proper disposal and recycling
                        of waste, contributing to a cleaner and greener
                        environment.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Investment Potential:</h4>
                <div>
                  <p>
                    Located in one of Muzaffarpur’s most rapidly developing
                    areas, GENX SHIVA offers excellent prospects for
                    appreciation in property value. The project’s proximity to
                    key infrastructure developments and commercial zones
                    positions it as a highly attractive investment opportunity
                    for both first-time homebuyers and seasoned investors
                    looking for long-term returns.
                  </p>
                </div>

                <h4 className="title-2">Proximity to Key Locations:</h4>
                <div>
                  <p>
                    GENX SHIVA’s strategic location ensures that everything you
                    need is within reach:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Schools & Colleges: </strong>Well-known
                        educational institutions such as [insert nearby
                        schools/colleges] are just a short drive away.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Healthcare Facilities: </strong>
                        Leading hospitals and healthcare centers, including
                        [insert nearby hospitals], are easily accessible.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping & Entertainment: </strong>The project
                        is close to shopping malls, supermarkets, and
                        entertainment centers, offering a well-rounded
                        lifestyle.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Transportation: </strong>The property enjoys
                        excellent connectivity to major roads and public
                        transport, making commuting within the city and to
                        neighboring areas easy and convenient.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why Choose GENX SHIVA?</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>High-Quality Construction:</strong>GENX SHIVA
                        has been built with attention to detail, using top-
                        quality materials and finishes to ensure durability and
                        luxury.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Comprehensive Amenities: </strong>
                        From fitness centers to recreation zones, the
                        development provides an array of amenities that enrich
                        your living experience.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Ideal Location: </strong>The project is
                        strategically located to offer convenience, peace, and
                        accessibility to all major areas of the city.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Eco-friendly Living: </strong>Sustainable living
                        features such as rainwater harvesting, energy-efficient
                        systems, and waste management contribute to a greener,
                        cleaner environment.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Investment Potential: </strong>With the area’s
                        rapid development and increasing demand for quality
                        housing, GENX SHIVA is a promising investment for the
                        future.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">
                  Move into a Lifestyle of Luxury and Comfort at GENX SHIVA!
                </h4>
                <div>
                  <p>
                    GENX SHIVA is not just a place to live; it’s a community
                    designed for modern living. With its superior location,
                    thoughtfully designed apartments, world-class amenities, and
                    sustainable features, this is the ideal choice for those
                    looking for a sophisticated, convenient, and comfortable
                    living experience in Muzaffarpur.
                  </p>
                </div>

                <h4 className="title-2">
                  Book your apartment at GENX SHIVA today and step into a new
                  era of modern living!
                </h4>
                <div>
                  <p>
                    For more information or to schedule a site visit, contact us
                    now.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
