import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
               
                <h1>GENX LOTUS</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Visweshraiya Nagar, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  GENX LOTUS is a premium residential development that features
                  spacious <strong>2BHK</strong> and{" "}
                  <strong>3BHK apartments</strong>, designed to offer you a
                  lifestyle that is both luxurious and practical. The project’s
                  sleek architecture, modern interiors, and a host of top-tier
                  amenities provide residents with a living experience that
                  meets the highest standards of comfort and style. Whether
                  you're a young professional, a growing family, or an investor,
                  GENX LOTUS promises to deliver an exceptional living
                  environment.
                </p>
                <p>
                  <p>
                    {" "}
                    <strong> Key Features & Highlights: </strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Prime Location:</strong>
                    </li>
                    <ul>
                      <li>
                        <p>
                          <strong>Strategic Location on Bailey Road: </strong>
                          GENX LOTUS is ideally situated in{" "}
                          <strong>Visweshraiya Nagar</strong>, a prime area on{" "}
                          <strong>Bailey Road</strong>, Patna’s most prominent
                          and well-connected thoroughfare. The area is known for
                          its excellent infrastructure, proximity to key
                          commercial hubs, and easy access to educational
                          institutions, healthcare centers, and entertainment
                          zones.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Connectivity: </strong>With seamless
                          connectivity to Patna’s key areas, GENX LOTUS offers
                          residents unparalleled convenience for commuting and
                          accessing essential services. Major roads, public
                          transport systems, and nearby landmarks make the
                          location highly accessible.
                        </p>
                      </li>
                    </ul>
                  </ol>
                </p>

                <h4 className="title-2">Modern Apartment Design:</h4>
                <div>
                  <p>
                    GENX LOTUS is designed to maximize both comfort and
                    functionality, offering well-planned, spacious apartments
                    that suit every lifestyle:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>2BHK and 3BHK Apartments: </strong>
                        Thoughtfully designed layouts featuring generous living
                        areas, spacious bedrooms, and ample natural light and
                        ventilation.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>High-End Finishes: </strong>
                        Premium quality materials, including{" "}
                        <strong>vitrified tiles, modular kitchens</strong>, and{" "}
                        <strong>elegant bathroom fittings</strong>, elevate the
                        overall aesthetics and provide a sense of luxury.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Balconies: </strong>
                        Each apartment comes with a spacious balcony that allows
                        residents to enjoy the fresh air and scenic views of the
                        surrounding area, offering the perfect spot to unwind
                        after a long day.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">World-Class Amenities:</h4>
                <div>
                  <p>
                    GENX LOTUS is equipped with a comprehensive range of modern
                    amenities, ensuring that every need of the residents is met:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>Round-the-clock security
                        with CCTV surveillance and professional security
                        personnel, ensuring the safety and peace of mind of all
                        residents.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Power Backup: </strong>
                        Full power backup for common areas and apartments,
                        ensuring uninterrupted service and comfort at all times.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Parking: </strong>Dedicated parking spaces for
                        residents and visitors, ensuring hassle-free parking.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Sustainability and Green Living:</h4>
                <div>
                  <p>
                    At GENX LOTUS, we believe in a sustainable future. The
                    project incorporates green design principles that help
                    conserve energy and reduce environmental impact:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Rainwater Harvesting: </strong>A rainwater
                        harvesting system to promote water conservation and
                        reduce dependency on municipal supplies.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Energy-Efficient Lighting: </strong>
                        The use of energy-efficient LED lights and appliances
                        ensures lower power consumption and reduced carbon
                        footprint.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Waste Management: </strong>A well-planned waste
                        disposal and recycling system contributes to a cleaner
                        and greener environment.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Proximity to Key Locations:</h4>
                <div>
                  <p>
                    GENX LOTUS is ideally located to offer you easy access to
                    the best that Patna has to offer:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Educational Institutions: </strong>Top schools,
                        colleges, and universities, such as [insert nearby
                        schools/colleges], are just a short distance away.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Healthcare Facilities: </strong>Leading
                        hospitals and medical centers like [insert names of
                        nearby hospitals] are within close proximity, ensuring
                        healthcare needs are easily accessible.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Landscaped Gardens:</strong>Beautifully
                        landscaped gardens and walking paths offer a serene
                        outdoor space for relaxation and rejuvenation.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Proximity to Key Locations:</h4>
                <div>
                  <p>
                    GENX LANDMARK is strategically located to offer easy access
                    to Patna’s important destinations:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Educational Institutions: </strong>Top schools,
                        colleges, and universities, such as [insert nearby
                        schools/colleges], are just a short distance away.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Healthcare Facilities: </strong>
                        Leading hospitals and medical centers like [insert names
                        of nearby hospitals] are within close proximity,
                        ensuring healthcare needs are easily accessible.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping & Entertainment: </strong>Prominent
                        shopping malls, restaurants, and entertainment zones,
                        including [insert names of nearby malls], are nearby,
                        making leisure activities convenient for residents.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Transportation: </strong>Excellent road
                        connectivity to key destinations, as well as easy access
                        to public transport options, ensures that commuting
                        within the city is quick and hassle-free.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Investment Opportunity:</h4>
                <div>
                  <p>
                    GENX LOTUS is not just an ideal home for your family—it is
                    also a smart investment. Located in one of Patna’s most
                    rapidly developing areas, the property offers great
                    potential for long-term value appreciation. With the area’s
                    ongoing infrastructural growth and increasing demand for
                    quality housing, GENX LOTUS represents a high-return
                    investment in the city’s real estate market.
                  </p>
                  <p>
                    <strong>Why Choose GENX LOTUS?</strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>High-Quality Construction:</strong>Built with
                        the finest materials and an attention to detail, GENX
                        LOTUS promises lasting durability and superior living
                        quality.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Contemporary Design: </strong>
                        The project features modern architectural elements, with
                        spacious and airy apartments that allow for natural
                        light and cross ventilation.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sustainability: </strong>Green features and
                        eco-friendly designs ensure that residents live in
                        harmony with nature, without compromising on luxury.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Complete Amenities: </strong>From fitness
                        centers to recreational spaces, GENX LOTUS offers
                        everything needed for a comfortable and convenient
                        lifestyle.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Prime Location: </strong>Located on{" "}
                        <strong>Bailey Road</strong>, one of Patna’s most
                        well-connected areas, ensuring ease of access to the
                        city’s major destinations.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sustainability: </strong>Eco-friendly features
                        like rainwater harvesting, energy-efficient systems, and
                        waste management make GENX LOTUS a green and sustainable
                        living choice.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">
                  Live the Life You Deserve at GENX LOTUS!
                </h4>
                <div>
                  <p>
                    GENX LOTUS offers the perfect combination of luxury,
                    convenience, and sustainability, making it the ideal choice
                    for anyone looking for a modern and comfortable living space
                    in Patna. With its spacious apartments, world-class
                    amenities, and prime location, GENX LOTUS ensures that every
                    aspect of your life is catered to with elegance and
                    sophistication.
                  </p>
                </div>

                <h4 className="title-2">
                  Book your dream home at GENX LOTUS today and experience a new
                  standard of living!
                </h4>
                <div>
                  <p>
                    For more information or to schedule a site visit, contact us
                    now.
                  </p>
                </div>
                
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
               
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
                

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
