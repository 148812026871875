import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class PortfolioV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__gallery-area mb-120">
        <div className="container">
          {/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
          <div className="ltn__gallery-active row ltn__gallery-info-hide---">
            <div className="ltn__gallery-sizer col-1" />
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-3 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <img
                    src={publicUrl + "assets/img/gallery/sbi.png"}
                    alt="Image"
                  />
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_2 col-lg-3 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img text-center">
                  <img
                    src={publicUrl + "assets/img/gallery/axis.png"}
                    alt="Image"
                  />
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_1 col-lg-3 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img text-center">
                  <img
                    src={publicUrl + "assets/img/gallery/hdfc.png"}
                    alt="Image"
                  />
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-3 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img text-center">
                  <img
                    src={publicUrl + "assets/img/gallery/icici.png"}
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="ltn__inline_description_1" style={{ display: "none" }}>
            <h4 className="first">
              This content comes from a hidden element on that page
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              quis mi eu elit tempor facilisis id et neque. Nulla sit amet sem
              sapien. Vestibulum imperdiet porta ante ac ornare. Nulla et lorem
              eu nibh adipiscing ultricies nec at lacus. Cras laoreet ultricies
              sem, at blandit mi eleifend aliquam. Nunc enim ipsum, vehicula non
              pretium varius, cursus ac tortor.
            </p>
            <p>
              Vivamus fringilla congue laoreet. Quisque ultrices sodales orci,
              quis rhoncus justo auctor in. Phasellus dui eros, bibendum eu
              feugiat ornare, faucibus eu mi. Nunc aliquet tempus sem, id
              aliquam diam varius ac. Maecenas nisl nunc, molestie vitae
              eleifend vel.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioV1;
