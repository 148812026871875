import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class VideoV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__about-us-area section-bg-1 bg-image-right-before pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    Residential Luxury Society
                  </h6>
                  <h1 className="section-title">
                    Making living spaces More Beautiful
                  </h1>
                  <p>
                    Discover unmatched luxury and convenience with modern design
                    and premium amenities crafted for discerning homeowners.
                  </p>
                </div>
                <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                  <li>
                    <i className="icon-done" />
                    Elegant Architecture
                  </li>
                  <li>
                    <i className="icon-done" />
                    World-Class Amenities
                  </li>
                  <li>
                    <i className="icon-done" />
                    Green Living
                  </li>
                  <li>
                    <i className="icon-done" />
                    Advanced Security
                  </li>
                  <li>
                    <i className="icon-done" />
                    Prime Locations
                  </li>
                  <li>
                    <i className="icon-done" />
                    High footfall areas
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoV2;
