import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GEN X CAPITAL</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Jamal Road, Near Fraser Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  GEN X Capital is an upcoming mixed-use development located at
                  the strategic and bustling Jamal Road, near Fraser Road in
                  Patna. Designed to cater to the growing demand for both
                  commercial and residential spaces in the heart of the city,
                  GEN X Capital seamlessly integrates modern living with
                  commercial functionality. This innovative project features a
                  well-planned commercial and residential complex, offering
                  businesses and families the ideal blend of accessibility,
                  comfort, and luxury.
                </p>
                <p>
                  The project consists of a multi-story building housing both
                  state-of-the-art commercial spaces on the lower floors and
                  stylish, spacious residential apartments on the upper floors.
                  Whether you are looking for a prime location for your business
                  or a modern, convenient home, GEN X Capital offers the
                  ultimate solution.
                </p>

                <h4 className="title-2">Project Highlights:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Mixed-Use Development: </strong>A perfect blend
                        of commercial and residential spaces, catering to
                        businesses and individuals seeking both workspaces and
                        homes under one roof.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Strategic Location: </strong>
                        Situated at Jamal Road, near Fraser Road, one of the
                        busiest and most sought-after areas in Patna, ensuring
                        maximum footfall and easy connectivity.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Modern Amenities:</strong>A A wide range of
                        amenities designed to elevate the living and working
                        experience, from recreational spaces to business
                        facilities.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Prime Investment Opportunity: </strong>
                        The commercial and residential blend offers great
                        investment potential, with a growing demand for quality
                        spaces in central Patna.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Detailed Features & Amenities:</h4>
                <div>
                  <p>
                    <strong>Commercial Spaces: </strong>
                    <ul>
                      <li>
                        <p>
                          <strong>Premium Office Spaces:</strong>
                          <ul>
                            <li>
                              <p>
                                The commercial portion of the building offers
                                spacious and flexible office spaces suitable for
                                a wide range of businesses, including retail
                                stores, offices, and corporate establishments.
                              </p>
                            </li>
                            <li>
                              <p>
                                High ceilings, large windows, and modern
                                interiors ensure a bright and professional work
                                environment.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>

                      <li>
                        <p>
                          <strong>Retail Spaces: </strong>
                          <ul>
                            <li>
                              <p>
                                Strategically designed retail spaces on the
                                ground and lower floors provide an ideal
                                location for stores, showrooms, and
                                service-based businesses, ensuring maximum
                                visibility and foot traffic.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>

                      <li>
                        <p>
                          <strong>High-Speed Elevators & Access: </strong>
                          <ul>
                            <li>
                              <p>
                                The commercial spaces are serviced by high-speed
                                elevators, ensuring quick and efficient movement
                                for both employees and clients.
                              </p>
                            </li>
                            <li>
                              <p>
                                Dedicated entry and exit points for commercial
                                tenants and customers, ensuring smooth traffic
                                flow.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>

                      <li>
                        <p>
                          <strong>Parking for Commercial Tenants:</strong>
                          <ul>
                            <li>
                              <p>
                                Ample parking spaces dedicated to commercial
                                tenants and their customers, ensuring
                                convenience and accessibility.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>
                    </ul>
                  </p>
                </div>

                <h4 className="title-2">Residential Apartments:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Spacious Residential Units: </strong>
                        <ul>
                          <li>
                            <p>
                              The residential section of GEN X Capital offers
                              1BHK, 2BHK, and 3BHK apartments designed with
                              modern aesthetics and functionality. The units are
                              spacious, with well-planned layouts and high-end
                              finishes.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Modern Interiors & Fixtures: </strong>
                        <ul>
                          <li>
                            <p>
                              Premium flooring (Vitrified Tiles in living areas,
                              laminated wooden flooring in bedrooms), modular
                              kitchens, and high-quality bathroom fixtures
                              ensure a luxurious living experience.
                            </p>
                          </li>
                          <li>
                            <p>
                              Each apartment is designed to maximize natural
                              light and ventilation, creating airy and
                              comfortable living spaces.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Balconies with Views: </strong>
                        <ul>
                          <li>
                            <p>
                              All apartments feature private balconies that
                              provide sweeping views of the city, offering a
                              peaceful retreat amidst the urban bustle.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Smart Home Features: </strong>
                        <ul>
                          <li>
                            <p>
                              Pre-installed smart home automation systems
                              (lighting, temperature control, security) ensure
                              convenience, energy efficiency, and a high
                              standard of living.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Private Parking for Residents: </strong>
                        <ul>
                          <li>
                            <p>
                              Dedicated parking spaces for residents with
                              additional visitor parking, ensuring easy access
                              and comfort.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">
                  Amenities for Both Commercial and Residential Areas:
                </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>
                        <ul>
                          <li>
                            <p>
                              The entire complex is equipped with
                              round-the-clock security, including CCTV
                              surveillance, intercom systems, and on-site
                              security personnel, ensuring safety for both
                              residents and businesses.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          High-Speed Internet and Communication Facilities:{" "}
                        </strong>
                        <ul>
                          <li>
                            <p>
                              Internet connectivity and modern communication
                              facilities are provided throughout the complex to
                              support both business and residential needs.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Power Backup: </strong>
                        <ul>
                          <li>
                            <p>
                              Full power backup for all common areas, elevators,
                              and essential services, ensuring uninterrupted
                              operation during power outages.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Recreational Areas: </strong>
                        <ul>
                          <li>
                            <p>
                              For residents, a well-equipped clubhouse and
                              recreational areas offer space for socializing,
                              relaxation, and fitness.
                            </p>
                          </li>
                          <li>
                            <p>
                              The building includes an outdoor lounge area and
                              green spaces for residents and visitors to unwind.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Landscaped Gardens: </strong>
                        <ul>
                          <li>
                            <p>
                              Beautifully designed landscaped gardens around the
                              complex provide a serene environment for
                              relaxation, with walking paths and seating areas.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Water Supply and Waste Management:</strong>
                        <ul>
                          <li>
                            <p>
                              Continuous water supply is ensured through an
                              advanced water filtration system. Efficient waste
                              management systems are also in place to maintain
                              cleanliness and hygiene.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Rainwater Harvesting:</strong>
                        <ul>
                          <li>
                            <p>
                              An eco-friendly rainwater harvesting system is
                              implemented to conserve water, contributing to
                              sustainable living practices.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantages:</h4>
                <div>
                  <p>
                    <strong>
                      Jamal Road and Fraser Road – The Heart of Patna:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Central Location: </strong>Situated in the
                        central business district, the project is surrounded by
                        major commercial, retail, and residential
                        establishments. Both residential and commercial tenants
                        will benefit from the high foot traffic and visibility
                        of this prime location.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Educational Institutions: </strong>
                        Top schools, colleges, and educational institutions are
                        within close proximity, including Patna University and
                        other renowned coaching centers, making it an ideal
                        choice for families.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Healthcare Facilities: </strong>Leading
                        hospitals like PMCH, Indira Gandhi Institute of Medical
                        Sciences, and various private healthcare centers are
                        just a short drive away, ensuring medical facilities are
                        always within reach.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping & Entertainment Hubs: </strong>Shopping
                        malls, local markets, entertainment centers, and
                        restaurants in the vicinity offer a wide range of
                        leisure and shopping options, ensuring a vibrant
                        lifestyle for residents and workers alike.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Excellent Connectivity: </strong>The project
                        enjoys excellent road connectivity to major city
                        landmarks, including Patna Junction, the international
                        airport, and key commercial zones, making it accessible
                        from all parts of the city and beyond.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Rapid Development Area: </strong>The area
                        surrounding Jamal Road and Fraser Road is undergoing
                        rapid infrastructure development, making it a prime
                        location for investment. The increasing demand for both
                        residential and commercial spaces ensures long-term
                        value appreciation.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why Choose GEN X Capital?</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Mixed-Use Convenience: </strong>
                        Combining both commercial and residential spaces in one
                        building, GEN X Capital offers the convenience of living
                        and working in the same location, ideal for
                        professionals and families.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Strategic Location: </strong>
                        Positioned in one of Patna’s most sought-after and
                        well-connected areas, ensuring excellent business
                        visibility and easy access for residents to amenities
                        and services.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Modern Amenities and Facilities: </strong>
                        From high-speed elevators and smart home features to
                        recreational spaces and 24/7 security, the project is
                        designed to offer a premium lifestyle.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Investment Potential: </strong>
                        The mixed-use nature of the project makes it an
                        attractive investment, with opportunities for both
                        commercial leasing and residential rentals, offering
                        multiple revenue streams.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
