import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                
                <h1>JK RESIDENCY</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  Near RPS Engineering College, Bailey Road, Saguna More, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  JK Residency is an upcoming premium residential project
                  strategically located near RPS Engineering College, Saguna
                  More, off Bailey Road in Patna. The project consists of a
                  meticulously designed single tower offering spacious,
                  well-ventilated apartments that combine contemporary design
                  with modern amenities. With its thoughtfully crafted
                  architecture and a range of luxurious features, JK Residency
                  is poised to become one of the most desirable addresses in
                  Patna.
                </p>
                <p>
                  Situated in a rapidly developing area, JK Residency offers a
                  perfect blend of convenience, comfort, and a sense of
                  community. It caters to the needs of modern families,
                  professionals, and individuals seeking a luxurious living
                  experience with easy access to essential services, educational
                  institutions, shopping, and leisure destinations.
                </p>
                

                <h4 className="title-2">Project Highlights:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          Single Tower, High-rise Residential Complex:{" "}
                        </strong>
                        JK Residency comprises a single tower with a limited
                        number of apartments, ensuring exclusivity, privacy, and
                        a strong sense of community.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Spacious Apartment Configurations: </strong>
                        Choose from 2BHK, 3BHK, and 4BHK apartments, designed to
                        offer ample space, natural light, and functionality.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Prime Location: </strong>
                        Situated in a strategic location near RPS Engineering
                        College and Saguna More, offering excellent connectivity
                        to major roads, educational institutions, shopping hubs,
                        and healthcare facilities.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Modern Architecture and Design: </strong>A
                        perfect blend of modern architecture and smart design
                        that enhances both aesthetic appeal and functionality.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Detailed Features & Amenities:</h4>
                <div>
                  <p>
                    <strong>Residential Units:</strong>
                    <ul>
                      <li>
                        <p>
                          <strong>Variety of Apartment Configurations:</strong>
                          <ul>
                            <li>
                              <p>
                                The project offers thoughtfully designed 2BHK,
                                3BHK, and 4BHK apartments, each with spacious
                                floor plans and functional layouts.
                              </p>
                            </li>
                            <li>
                              <p>
                                Apartments come with large windows for maximum
                                natural light, open layouts for better
                                ventilation, and balconies that enhance the
                                living experience.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>

                      <li>
                        <p>
                          <strong>High-Quality Interior Finishes:</strong>
                          <ul>
                            <li>
                              <p>
                                Premium flooring (Vitrified tiles and wooden
                                laminates) in living rooms and bedrooms.
                              </p>
                            </li>
                            <li>
                              <p>
                                Designer kitchens with modular setups and modern
                                fittings.
                              </p>
                            </li>
                            <li>
                              <p>
                                Luxurious bathrooms with high-quality fixtures
                                and modern sanitary ware.
                              </p>
                            </li>
                            <li>
                              <p>
                                Spacious balconies for relaxation and outdoor
                                enjoyment.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>

                      <li>
                        <p>
                          <strong>Smart Home Features:</strong>
                          <ul>
                            <li>
                              <p>
                                Provision for smart home automation systems
                                (lighting, temperature control, security, and
                                more), providing residents with greater comfort,
                                convenience, and energy efficiency.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>

                      <li>
                        <p>
                          <strong>Private Parking Spaces:</strong>
                          <ul>
                            <li>
                              <p>
                                Dedicated parking spaces for residents with
                                additional visitor parking, ensuring smooth
                                parking arrangements for all residents and their
                                guests.
                              </p>
                            </li>
                          </ul>
                        </p>
                      </li>
                    </ul>
                  </p>
                </div>

                <h4 className="title-2">Modern Amenities:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>24/7 Security: </strong>
                        <ul>
                          <li>
                            <p>
                              Round-the-clock security services with CCTV
                              surveillance, security guards, and intercom
                              systems to ensure the safety and well-being of all
                              residents.
                            </p>
                          </li>
                          <li>
                            <p>
                              Gated entry and access control for added security
                              and privacy.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Power Backup: </strong>
                        <ul>
                          <li>
                            <p>
                              Full power backup for all common areas, elevators,
                              and selected apartments to ensure uninterrupted
                              living and smooth daily operations.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Water Supply & Waste Management: </strong>
                        <ul>
                          <li>
                            <p>
                              24/7 water supply with modern water filtration
                              systems.
                            </p>
                          </li>
                          <li>
                            <p>
                              Sustainable waste management practices, including
                              segregated waste disposal, ensuring a clean and
                              eco-friendly environment.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Rainwater Harvesting: </strong>
                        <ul>
                          <li>
                            <p>
                              An eco-friendly rainwater harvesting system to
                              conserve water and ensure a sustainable water
                              supply for residents.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantages:</h4>
                <div>
                  <p>
                    <strong>
                      Strategic Location near Saguna More, Bailey Road:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Proximity to Key Areas: </strong>
                        <ul>
                          <li>
                            <p>
                              <strong>Educational Institutions: </strong>
                              The project is situated close to RPS Engineering
                              College, one of the leading educational
                              institutions in the city. Additionally, Patna
                              University, schools, and coaching centers are
                              easily accessible.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Healthcare Facilities: </strong>
                              Leading hospitals like PMCH, Anugrah Narayan
                              Magadh Medical College, and private healthcare
                              facilities are just a short drive away, ensuring
                              that residents have access to top-tier medical
                              services.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Shopping and Entertainment: </strong>
                              Major shopping centers, malls like Patna Mall, and
                              multiplexes are easily reachable. The project is
                              also located near entertainment hubs, making it
                              convenient for residents to shop, dine, and
                              unwind.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Connectivity: </strong>
                        <ul>
                          <li>
                            <p>
                              <strong>Bailey Road: </strong>
                              The project enjoys excellent connectivity to
                              Bailey Road, one of the busiest and most prominent
                              roads in Patna, ensuring smooth access to other
                              parts of the city.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Public Transport: </strong>
                              Well-connected by public transport, with easy
                              access to buses, auto-rickshaws, and taxi
                              services, making commuting hassle-free.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Rail and Air: </strong>
                              Patna Junction (railway station) and Patna
                              International Airport are within a short drive
                              from the project, ensuring easy connectivity for
                              long-distance travel.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Rapid Infrastructure Development: </strong>
                        <ul>
                          <li>
                            <p>
                              The Saguna More area and its surrounding
                              neighborhoods are undergoing rapid infrastructural
                              development, including improved roads, public
                              transport facilities, and commercial spaces. This
                              makes JK Residency an ideal investment with high
                              potential for property value appreciation.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why Choose JK Residency?</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Exclusivity & Privacy: </strong>With a single
                        tower configuration, JK Residency ensures that you
                        experience a close-knit community with the luxury of
                        privacy, a feature that is often difficult to find in
                        larger developments.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Prime Location: </strong>
                        Situated in the heart of the city’s growth corridor, it
                        provides access to educational institutions, healthcare,
                        and shopping, making it an ideal home for both students
                        and working professionals.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Luxurious Living: </strong>The modern design,
                        spacious apartments, and top-notch amenities combine to
                        offer a superior living experience.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Sustainable Design: </strong>With smart home
                        features, rainwater harvesting, and waste management,
                        the project is designed with sustainability in mind,
                        promoting eco-friendly living.
                      </p>
                    </li>
                  </ul>
                </div>

               
               
              
                
                
              
              
                
               
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
               
                {/* Form Widget */}
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>
              

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
