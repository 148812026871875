import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GENX ARYA</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX ARYA</strong>, a distinguished
                  residential development located at the heart of Patna's
                  thriving cityscape. Positioned at the prime location of{" "}
                  <strong>RPS More, Bailey Road</strong>, this completed single-
                  tower apartment complex offers an exceptional living
                  experience that combines elegance, convenience, and modern
                  design.
                  <strong>GENX ARYA</strong> is crafted for those who seek a
                  luxurious, comfortable, and connected lifestyle in one of
                  Patna's most coveted neighborhoods.
                </p>
                <p>
                  With spacious, well-designed apartments and state-of-the-art
                  amenities, <strong>GENX ARYA</strong> caters to the needs of
                  urban dwellers who value both tranquility and accessibility.
                  This is where you can enjoy the best of city living while
                  being surrounded by thoughtful architecture and premium
                  services.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Well-Designed Apartments:</strong>
                        The project offers a variety of 2 BHK, 3 BHK, and 4 BHK
                        apartments, each designed to provide ample space,
                        natural light, and ventilation. The interiors are
                        crafted with premium finishes and modern fixtures,
                        ensuring a blend of comfort, luxury, and functionality.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Exclusive Amenities: GENX ARYA </strong>
                        boasts an array of modern amenities that enhance the
                        living experience, including:
                        <ul>
                          <li>
                            <p>
                              <strong>Children’s Play Area </strong>
                              for safe and fun-filled activities
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>24/7 Power Backup </strong>
                              for uninterrupted living
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Spacious Parking Areas </strong>
                              for residents and guests
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Round-the-Clock Security: </strong>
                        Safety and security are paramount at{" "}
                        <strong>GENX ARYA</strong>. The development is equipped
                        with:
                        <ul>
                          <li>
                            <p>
                              <strong>24/7 CCTV Surveillance </strong>
                              and on-site security personnel
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Smart Access Control Systems </strong>
                              ensuring secure entry
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>
                                Fire Safety and Emergency Protocols{" "}
                              </strong>
                              for peace of mind
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Sustainability: </strong>
                        Designed with eco-friendly features like
                        energy-efficient lighting, water- saving systems, and
                        green landscaping, <strong>GENX ARYA</strong> promotes a
                        sustainable lifestyle while enhancing the overall living
                        experience.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          Prime Address on Bailey Road: GENX ARYA{" "}
                        </strong>
                        enjoys a prime location at{" "}
                        <strong>RPS More, Bailey Road</strong>, one of Patna’s
                        most important and well-connected areas. The project's
                        central location ensures you are well-connected to all
                        parts of the city.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Superior Connectivity: </strong>
                        The development offers excellent connectivity to{" "}
                        <strong>Patna Junction, Patna Airport</strong>, and
                        major transit routes. Whether you're traveling by car,
                        bus, or train, commuting from <strong>GENX ARYA</strong>{" "}
                        is hassle-free.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Proximity to Key Institutions: </strong>Leading
                        educational institutions such as{" "}
                        <strong>RPS School, St. Xavier’s College</strong>, and
                        top healthcare facilities like
                        <strong>Arya Hospital</strong> and <strong>NMCH</strong>{" "}
                        are just minutes away, making this a convenient location
                        for families.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Shopping & Entertainment: </strong>With shopping
                        malls, restaurants, cinemas, and recreational centers
                        nearby, residents of <strong>GENX ARYA</strong> enjoy
                        easy access to all the lifestyle amenities Patna has to
                        offer.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why GENX ARYA?</h4>
                <div>
                  <p>
                    <strong>GENX ARYA</strong> is the perfect choice for those
                    who seek a blend of modern living, luxury, and convenience.
                    Whether you're looking for a family home, a professional
                    living space, or an investment opportunity,{" "}
                    <strong>GENX ARYA</strong> provides everything you need for
                    a fulfilling life. Its prime location, thoughtfully designed
                    apartments, world-class amenities, and commitment to safety
                    make it an exceptional place to live.
                  </p>
                </div>

                <h4 className="title-2">
                  Experience the art of fine living at GENX ARYA – Where luxury
                  meets lifestyle in the heart of Patna.
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
