import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProjectDetailsSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <h1>GENX Green</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  RPS More, Bailey Road, Patna
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  Welcome to <strong>GENX GREEN</strong>, a distinguished
                  residential development that embodies the perfect harmony of
                  modern living and natural tranquility. Nestled in the highly
                  sought-after location of{" "}
                  <strong>DPS More, Bailey Road</strong>, this completed
                  single-tower apartment complex offers a unique living
                  experience where convenience, comfort, and sustainability come
                  together.
                </p>
                <p>
                  <strong>GENX GREEN</strong> stands as a beacon of contemporary
                  design, offering a lifestyle enriched by abundant greenery,
                  superior amenities, and the finest living spaces. Its
                  strategic location ensures you are connected to all essential
                  services, yet enveloped in a serene environment that promises
                  peace and relaxation.
                </p>

                <h4 className="title-2">Project Features: </h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Spacious Apartments:</strong>
                        The project features meticulously designed 2 BHK, 3 BHK,
                        and 4 BHK apartments, each with expansive layouts and
                        premium finishes. The apartments are crafted to offer an
                        abundance of natural light and ventilation, ensuring a
                        bright and airy living space.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Eco-Friendly Living: </strong>
                        Staying true to its name, <strong>
                          GENX GREEN
                        </strong>{" "}
                        emphasizes sustainability. The development incorporates
                        energy-efficient technologies, rainwater harvesting
                        systems, and green landscaping, making it an ideal
                        choice for eco-conscious residents who value
                        sustainability without compromising on luxury.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Modern Amenities: GENX GREEN</strong>
                        offers a host of modern amenities designed to elevate
                        your lifestyle, including:
                      </p>
                      <ul>
                        <li>
                          <p>
                            <strong>24/7 Power Backup</strong>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Parking Spaces</strong>
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <p>
                        <strong>Round-the-Clock Security: </strong>
                        Your safety is our priority. The project includes CCTV
                        surveillance, manned security, access control, and fire
                        safety systems, ensuring peace of mind for all
                        residents.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Well-Connected & Convenient: </strong>
                        Whether you are commuting to work, shopping, or heading
                        out for leisure, the location of{" "}
                        <strong>GENX GREEN</strong> on{" "}
                        <strong>Bailey Road</strong> offers excellent
                        connectivity to all major landmarks in Patna, making
                        daily life both efficient and enjoyable.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location Advantage:</h4>
                <div>
                  <ul>
                    <li>
                      <p>
                        <strong>Prime Location:</strong>
                        Situated at <strong>DPS More</strong>, Bailey Road, this
                        location is one of the most sought- after addresses in
                        Patna, providing easy access to educational
                        institutions, hospitals, shopping malls, and key
                        business districts.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Close Proximity to Key Hubs: </strong>
                        The project is just a short drive away from important
                        areas such as{" "}
                        <strong>Patna Junction, Patna Airport</strong>, and{" "}
                        <strong>Patna Sahib Gurudwara.</strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Educational Institutes & Healthcare: </strong>
                        Leading schools, colleges, and hospitals are within
                        close proximity, ensuring convenience for families with
                        children and easy access to essential services.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Connectivity: </strong>
                        Well connected by public transport, with major roads and
                        bus terminals nearby, ensuring easy connectivity to
                        every corner of Patna.
                      </p>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Why GENX GREEN?</h4>
                <div>
                  <p>
                    <strong>GENX GREEN</strong> is not just a place to live,
                    it’s a lifestyle to embrace. Offering a harmonious blend of
                    comfort, luxury, and sustainability, this development caters
                    to modern living while contributing positively to the
                    environment. With its ideal location, world-class amenities,
                    and eco-conscious design, <strong>GENX GREEN</strong>{" "}
                    promises an unparalleled living experience.
                  </p>
                  <p>
                    Whether you’re looking for a new home or a sound investment,
                    <strong>GENX GREEN</strong> offers the perfect combination
                    of elegance, sustainability, and practicality.
                  </p>
                </div>

                <h4 className="title-2">
                  Discover the essence of refined living at GENX GREEN – Where
                  luxury meets nature in the heart of Patna.
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                <div className="widget ltn__form-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Drop Messege For Book
                  </h4>
                  <form action="#">
                    <input
                      type="text"
                      name="yourname"
                      placeholder="Your Name*"
                    />
                    <input
                      type="text"
                      name="youremail"
                      placeholder="Your e-Mail*"
                    />
                    <textarea
                      name="yourmessage"
                      placeholder="Write Message..."
                      defaultValue={""}
                    />
                    <button type="submit" className="btn theme-btn-1">
                      Send Messege
                    </button>
                  </form>
                </div>

                {/* Social Media Widget */}
                <div className="widget ltn__social-media-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Follow us
                  </h4>
                  <div className="ltn__social-media-2">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Instagram">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Banner Widget */}
                <div className="widget ltn__banner-widget d-none go-top">
                  <Link to="/shop">
                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsSection;
