import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CompletedProjectList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <div className="ltn__product-area ltn__product-gutter mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content ">
                  <div className="" id="liton_product_list">
                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                      <div className="row">
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/genx-landmark-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/GENX LANDMARK.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-landmark-details">
                                      GenX Landmark
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> RPS
                                          More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to GENX LANDMARK, a premier
                                    residential development offering
                                    unparalleled luxury, comfort, and modern
                                    living. Strategically located at RPS
                                    T-Point, near RPS More, Patna, this world-
                                    class residential complex promises an
                                    elevated lifestyle with its four exquisitely
                                    designed towers, exceptional amenities, and
                                    prime location.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Swimming Pool
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Temple
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Party Hall
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Jogging Track
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Kids Play Area
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-icon-details">
                                      GenX Icon
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" />{" "}
                                          Mangalam Colony More, Nearby Saguna
                                          More, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX ICON</strong>, a
                                    premier
                                    <strong>
                                      commercial-cum-residential apartment
                                      complex
                                    </strong>
                                    designed to offer a perfect blend of
                                    business and lifestyle in one of Patna’s
                                    most vibrant locations. Situated at the
                                    prime address of{" "}
                                    <strong>Mangalam Colony More</strong>, near
                                    <strong>Saguna More</strong>, this iconic
                                    development offers an unparalleled living
                                    and working experience. With its sleek
                                    design, modern amenities, and unbeatable
                                    connectivity, GENX ICON is destined to be a
                                    landmark in Patna’s real estate landscape.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/genx-icon-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/product-3/GENX ICON.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/genx-lotus-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/GENX LOTUS.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-lotus-details">
                                      GenX Lotus
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" />{" "}
                                          Visweshraiya Nagar, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX LOTUS</strong>, a
                                    luxurious and contemporary residential
                                    project located in the heart of{" "}
                                    <strong>Visweshraiya Nagar</strong>, Bailey
                                    Road, Patna. This stunning{" "}
                                    <strong>
                                      single-tower apartment complex
                                    </strong>{" "}
                                    offers a perfect blend of modern design,
                                    world-class amenities, and an unbeatable
                                    location. With an emphasis on quality,
                                    comfort, and convenience, GENX LOTUS is an
                                    ideal choice for those looking for a
                                    sophisticated living space in one of Patna’s
                                    most sought-after localities.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-namrata-details">
                                      GenX Namrata
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" />
                                          RPS More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to GENX NAMRATA, an exclusive
                                    residential development nestled in the heart
                                    of RPS More, Bailey Road, Patna. This
                                    completed single-tower apartment complex is
                                    designed to offer modern urban living at its
                                    finest. With its contemporary architecture,
                                    spacious layouts, and world- class
                                    amenities, GENX NAMRATA is an epitome of
                                    comfort, luxury, and convenience.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/genx-namrata-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/genx/GENXNAMRATA.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/genx-villa-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/GENXVILLA.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-villa-details">
                                      GenX Villa
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> RPS
                                          More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to GENX VILLA, a prestigious
                                    residential development located at the prime
                                    address of RPS More, Bailey Road, Patna.
                                    This completed single-tower apartment
                                    complex offers a perfect fusion of luxury,
                                    modern design, and strategic location,
                                    making it one of the most sought-after
                                    living destinations in the city.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>  

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-arya-details">
                                      GenX Arya
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> RPS
                                          More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX ARYA</strong>, a
                                    distinguished residential development
                                    located at the heart of Patna's thriving
                                    cityscape. Positioned at the prime location
                                    of <strong>RPS More, Bailey Road</strong>,
                                    this completed single- tower apartment
                                    complex offers an exceptional living
                                    experience that combines elegance,
                                    convenience, and modern design.
                                    <strong>GENX ARYA</strong> is crafted for
                                    those who seek a luxurious, comfortable, and
                                    connected lifestyle in one of Patna's most
                                    coveted neighborhoods.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/genx-arya-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/genx/GENX ARYA.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/genx-ramashram-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/GENX RAMASHRAM.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-ramashram-details">
                                      GenX Ramashram
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" />{" "}
                                          Seikhpura More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX RAMASHRAM</strong>,
                                    a premium residential development that
                                    redefines modern living in the heart of
                                    Patna. Strategically located at the prime
                                    locale of
                                    <strong>Seikhpura More, Bailey Road</strong>
                                    , this iconic single-tower apartment complex
                                    offers a perfect blend of contemporary
                                    design, luxury, and convenience.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-city-details">
                                      GenX City
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> RPS
                                          More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX CITY</strong>, a
                                    prestigious residential development designed
                                    for those who seek a blend of modern luxury,
                                    convenience, and a prime location. Located
                                    at <strong>RPS More, Bailey Road</strong>,
                                    Patna, this completed single-tower apartment
                                    complex offers an unparalleled living
                                    experience in one of the city's most
                                    sought-after neighborhoods.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/genx-city-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/genx/GENX CITY.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/genx-green-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/GENX GREEN.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-green-details">
                                      GenX Green
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> DPS
                                          More, Bailey Road, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX GREEN</strong>, a
                                    distinguished residential development that
                                    embodies the perfect harmony of modern
                                    living and natural tranquility. Nestled in
                                    the highly sought-after location of{" "}
                                    <strong>DPS More, Bailey Road</strong>, this
                                    completed single-tower apartment complex
                                    offers a unique living experience where
                                    convenience, comfort, and sustainability
                                    come together.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-oasis-details">
                                      GenX Oasis
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> RPS
                                          More, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to <strong>GENX OASIS</strong>, an
                                    exquisite residential development that
                                    offers a serene living experience right in
                                    the heart of Patna. Located at the prime
                                    junction of{" "}
                                    <strong>RPS More, Bailey Road</strong>, this
                                    completed single-tower apartment complex
                                    redefines modern urban living. Designed to
                                    provide a perfect balance between comfort,
                                    luxury, and convenience,{" "}
                                    <strong>GENX OASIS</strong> is a true
                                    retreat in the bustling city of Patna.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Intercom
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              Car Parking
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              Fire Safety
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Water supply
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Security
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                              24x7 Power Back Up
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/genx-oasis-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/genx/GENX OASIS.png"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompletedProjectList;
